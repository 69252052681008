import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';
import NewsletterQuery from 'Query/Newsletter.query';
import Newsletter from './Newsletter.component';

export const mapDispatchToProps = dispatch => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

class NewsletterContainer extends PureComponent {
    state = {
        email: '',
        showGdpr: this.props.showGdpr || false
    };

    containerFunctions = ({
        onNewsletterSubmitSuccess: this._onNewsletterSubmitSuccess.bind(this),
        onNewsletterInputFocus: this._onNewsletterInputFocus.bind(this),
        onNewsletterInputChange: this._onNewsletterInputChange.bind(this)
    });

    containerProps = () => {
        const { email, showGdpr } = this.state;
        const { buttonText } = this.props;

        return {
            email,
            showGdpr,
            buttonText
        };
    };

    _onNewsletterSubmitSuccess(fields) {
        const { showNotification, onNewsletterSuccess, source } = this.props;

        let args = fields;

        if (source) {
            args = {
                ...args,
                source
            }
        }

        const mutation = NewsletterQuery.getAddNewsletterMutation(args);

        fetchMutation(mutation).then(
            ({ addNewsletter: { type, message } }) => {
                if (onNewsletterSuccess) {
                    onNewsletterSuccess();
                }
                showNotification(type, message);
            },
            // eslint-disable-next-line no-console
            error => showNotification('error', __('Error! Something went wrong')) && console.log(error)
        );

        this.setState({
            email: ''
        });
    }

    _onNewsletterInputChange(value) {
        this.setState({
            email: value
        });
    }

    _onNewsletterInputFocus() {
        this.setState({
            showGdpr: true
        });
    }

    render() {
        const { buttonText } = this.props;
        return (
            <Newsletter
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(NewsletterContainer);
