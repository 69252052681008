import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { history } from 'Route';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import HeaderSticky from './HeaderSticky.component';

export class HeaderStickyContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isSticky: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.addEvents();
    }

    componentWillUnmount() {
        this.removeEvents();
    }

    handleScroll() {
        const { location: { pathname } } = this.props;

        const elTabs = document.getElementsByClassName('tabs')[0];
        this.elStickyHeader = document.getElementsByClassName('HeaderSticky')[0];

        if (window.pageYOffset > 219) {
            document.body.classList.add('isSticky');
            this.setState({ isSticky: true });
        } else {
            document.body.classList.remove('isSticky');
            this.setState({ isSticky: false });
        }

        if (elTabs) {
            const swapStickyThreshold = elTabs.getBoundingClientRect().top - 84;

            if (swapStickyThreshold < 0) {
                this.elStickyHeader.style.top = `${swapStickyThreshold}px`;
            } else {
                this.elStickyHeader.style.top = 0;
            }
        }

        if (pathname === '/black-friday' && document.getElementsByClassName('BlackFriday-Menu')[0]) {
            const swapStickyThreshold = document.getElementsByClassName('BlackFriday-Menu')[0].getBoundingClientRect().top - 84;

            if (swapStickyThreshold < 0) {
                this.elStickyHeader.style.top = `${swapStickyThreshold}px`;
            } else {
                this.elStickyHeader.style.top = 0;
            }
        }
    }

    addEvents() {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1366) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    removeEvents() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    containerProps = () => {
        const { isSticky } = this.state;

        const {
            location: {
                pathname
            }
        } = history;
        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            isSticky,
            isCheckout
        };
    };

    render() {
        return (
            <HeaderSticky
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(HeaderStickyContainer);
