import SourceCmsBlockContainer from 'SourceComponent/CmsBlock/CmsBlock.container';

export class CmsBlockContainer extends SourceCmsBlockContainer {
    containerProps = () => {
        const { cmsBlock } = this.state;
        const { collapsible, mix, variables } = this.props; // Make sure to include variables
        return {
            cmsBlock,
            collapsible,
            mix,
            variables // Pass the dynamic variables to CmsBlock
        };
    };
}

export default CmsBlockContainer;
