import {
    isSignedIn as isInitiallySignedIn
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    CLOSE_MY_ACCOUNT_POPOVER,
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS
} from './MyAccount.action';

export const initialState = {
    isSignedIn: isInitiallySignedIn(),
    passwordResetStatus: false,
    isPasswordForgotSend: false,
    customer: isInitiallySignedIn() ? BrowserDatabase.getItem('customer') : {},
    popoverClosedByUser: false
};

const MyAccountReducer = (state = initialState, action) => {
    const { status, customer, locker } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_SIGN_IN_STATUS:
        return {
            ...state,
            isSignedIn: status,
            popoverClosedByUser: false
        };

    case UPDATE_CUSTOMER_PASSWORD_RESET_STATUS:
        return {
            ...state,
            passwordResetStatus: status
        };

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS:
        return {
            ...state,
            isPasswordForgotSend: !state.isPasswordForgotSend
        };

    case UPDATE_CUSTOMER_DETAILS:
        return {
            ...state,
            customer
        };

    case CLOSE_MY_ACCOUNT_POPOVER:
        return {
            ...state,
            popoverClosedByUser: true
        }

    default:
        return state;
    }
};

export default MyAccountReducer;
