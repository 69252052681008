import PropTypes from 'prop-types';
import SourceCmsBlock from 'SourceComponent/CmsBlock/CmsBlock.component';
import Html from 'Component/Html';
import ExpandableContent from 'Component/ExpandableContent';
import { ChildrenType } from 'Type/Common';

export class CmsBlock extends SourceCmsBlock {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool
        }),
        children: ChildrenType,
        collapsible: PropTypes.bool,
        mix: PropTypes.string,
        variables: PropTypes.object // Add this to handle dynamic variables
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        collapsible: false,
        mix: '',
        variables: {} // Default empty object for variables
    };

    // Function to replace placeholders with actual variable values
    replaceVariables(content) {
        const { variables } = this.props;

        if (!content || !variables) {
            return content;
        }

        // Replace each {{var variableName}} in the content
        Object.keys(variables).forEach(key => {
            const regex = new RegExp(`{{var ${key}}}`, 'g');
            content = content.replace(regex, variables[key]);
        });

        return content;
    }

    render() {
        const {
            cmsBlock: {
                identifier, title, content, disabled
            },
            collapsible,
            mix
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        // Replace variables in content before rendering
        const processedContent = this.replaceVariables(content);

        if (collapsible) {
            return (
                <ExpandableContent
                    heading={ title }
                    mix={ mix ? { block: mix } : null }
                >
                    <Html content={ processedContent } />
                </ExpandableContent>
            );
        }

        return (
            <div
                block="CmsBlock"
                elem="Wrapper"
            >
                <Html content={ processedContent } />
            </div>
        );
    }
}

export default CmsBlock;
