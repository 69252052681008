import { GET_BLOG_POSTS } from './Blog.action';

export const initialState = {
    posts: [],
    isLoading: true
};

const BlogReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
    case GET_BLOG_POSTS:
        const { posts } = action;

        return {
            ...state,
            posts,
            isLoading: false
        };
    default:
        return state;
    }
};

export default BlogReducer;
