export const PDP = 'pdp';
export const PLP = 'plp';
export const POPUP = 'popup';
export const CATEGORY = 'category';
export const CUSTOMER_ACCOUNT = 'customer_account';
export const CUSTOMER_SUB_ACCOUNT = 'customer_sub_account';
export const CUSTOMER_ACCOUNT_PAGE = 'customer_account_page';
export const HOME_PAGE = 'home';
export const MENU = 'menu';
export const MENU_SUBCATEGORY = 'menu_subcategory';
export const SEARCH = 'search';
export const FILTER = 'filter';
export const CART = 'cart';
export const CART_OVERLAY = 'cart_overlay';
export const CART_EDITING = 'cart_editing';
export const CHECKOUT = 'checkout';
export const CMS_PAGE = 'cms-page';
export const MY_ACCOUNT = 'my-account';

export const URL_REWRITE = 'url-rewrite';
export const PASSWORD_CHANGE = 'password-change';
export const CONFIRM_ACCOUNT = 'confirm_account';
export const FORGOT_PASSWORD = 'forgot_password';
export const CALL = 'call';

export const NEWSLETTER_PAGE = 'newsletter-page';
export const MIERCURI_PAGE = 'miercuri-page';
export const PROMOTIONS = 'promotions';
export const GIFT_CARD = 'gift-card';
export const LOGIN = 'login';
export const REGISTER = 'register';

export const BLACKFRIDAYTEASING_PAGE = 'blackfridayteasing-page';
export const BLACKFRIDAY_PAGE = 'blackfriday';

export const BEAUTY_ADVENT_CALENDAR = 'beautyadventcalendar';
export const CHECKOUT_DELIVERY_OVERLAY = 'checkout_delivery_overlay';

export const POPOVER_CLOSED_BY_USER = 'popoverClosedByUser';

export const GEROVITAL_MUSTHAVE_PAGE = 'gerovital-must-have';
export const TEASING_PAGE = 'avanpremiera-gerovital';

export const RETRY_PAYU_PAGE = 'retry-payu-page';
