import { connect } from 'react-redux';
import CmsPage from 'Route/CmsPage';
import './NoMatch.style.scss';

export const mapStateToProps = state => ({
    pageIdentifiers: state.ConfigReducer.cms_no_route
});

export const NoMatchContainer = props => (
    <div block="NoMatch">
        <CmsPage { ...props } isBreadcrumbsActive={ false } />
    </div>
);

export default connect(mapStateToProps)(NoMatchContainer);
