import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {showPopup} from 'Store/Popup/Popup.action';
import {hideActiveOverlay} from 'Store/Overlay/Overlay.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import {goToPreviousNavigationState} from 'Store/Navigation/Navigation.action';
import {TOP_NAVIGATION_TYPE} from 'Store/Navigation/Navigation.reducer';
import {isSignedIn} from 'Util/Auth';
import PromotionPopup from './PromotionPopup.component';

import {
    PROMOTION_POPUP,
    ONE_HOUR_IN_SECONDS
} from './PromotionPopup.config';

export const mapStateToProps = state => ({
    promotionConfig: state.ConfigReducer.getPopupInformation
});

export const mapDispatchToProps = dispatch => ({
    showPopup: payload => dispatch(showPopup(PROMOTION_POPUP, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

export class PromotionPopupContainer extends PureComponent {
    containerFunctions = ({
        handleClosePopup: this.handleClosePopup.bind(this),
    });

    componentDidMount() {
        this.openPopup();
    }

    componentDidUpdate(prevProps) {
        const {promotionConfig} = this.props;
        const {promotionConfig: prevPromotionConfig} = prevProps;
        if (promotionConfig?.enabled !== prevPromotionConfig?.enabled) {
            this.openPopup();
        }
    }


    openPopup() {

        const {showPopup, promotionConfig} = this.props;

        if (!promotionConfig?.enabled || !promotionConfig?.block_id || !promotionConfig?.popup_timer) {
            return;
        }

        let lifetime = ONE_HOUR_IN_SECONDS;

        if (!promotionConfig?.popup_lifetime) {
            lifetime = promotionConfig.popup_lifetime;
        }

        if (!BrowserDatabase.getItem(PROMOTION_POPUP)) {
            setTimeout(() => {
                const {location: {pathname}} = this.props;
                const pageWhereNotShowing = [
                    '/cart',
                    '/checkout/shipping',
                    '/checkout/billing',
                    '/checkout/success',
                    '/miercuri',
                    '/newsletter',
                    '/account/login',
                    '/account/register',
                    '/account/forgotPassword'];

                if (!BrowserDatabase.getItem(PROMOTION_POPUP) && !pageWhereNotShowing.includes(pathname)) {
                    showPopup({
                        action: PROMOTION_POPUP,
                        title: __('PROMOTION')
                    });
                }
                BrowserDatabase.setItem(true, PROMOTION_POPUP, lifetime);
            }, promotionConfig.popup_timer);
        }
    }

    handleClosePopup() {
        const {hideActiveOverlay, goToPreviousHeaderState, promotionConfig} = this.props;
        hideActiveOverlay();
        goToPreviousHeaderState();
        let lifetime;

        if (promotionConfig?.popup_lifetime) {
            lifetime = promotionConfig.popup_lifetime;
        } else {
            lifetime = ONE_HOUR_IN_SECONDS;
        }
        BrowserDatabase.setItem(true, PROMOTION_POPUP, lifetime);
    }

    containerProps = () => {
        const {promotionConfig} = this.props;

        return {
            promotionConfig
        };
    };

    render() {
        const {promotionConfig} = this.props;

        if (!promotionConfig?.enabled || !promotionConfig?.block_id) {
            return null;
        }

        return (
            <PromotionPopup
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionPopupContainer));
