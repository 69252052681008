export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD';
export const UPDATE_IS_LEGAL_PERSON = 'UPDATE_IS_LEGAL_PERSON';

/** @namespace Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/** @namespace Store/Checkout/Action/updateIsLegalPerson */
export const updateIsLegalPerson = (isLegalPerson) => ({
    type: UPDATE_IS_LEGAL_PERSON,
    isLegalPerson
});
