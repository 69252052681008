import {
    CartDispatcher as SourceCartDispatcher,
    GUEST_QUOTE_ID
} from 'SourceStore/Cart/Cart.dispatcher';
import CartQuery from 'Query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery, fetchMutation } from 'Util/Request';
import GoogleTagManager from 'Component/GoogleTagManager/GoogleTagManager.component';
import { getExtensionAttributes } from 'Util/Product';

export * from 'SourceStore/Cart/Cart.dispatcher';

export class CartDispatcher extends SourceCartDispatcher {

    updateInitialCartData(dispatch) {
        const guestQuoteId = this._getGuestQuoteId();

        if (isSignedIn()) {
            // This is logged in customer, no need for quote id
            this._syncCartWithBE(dispatch);
        } else if (guestQuoteId) {
            // This is guest
            this._syncCartWithBE(dispatch, guestQuoteId);
        } else {
            // This is guest, cart is empty
            // Need to create empty cart and save quote
            this.createGuestEmptyCart(dispatch);
        }
    }

    createGuestEmptyCart(dispatch) {
        return this._createEmptyCart(dispatch).then(
            (data) => {
                BrowserDatabase.setItem(data, GUEST_QUOTE_ID);
                this._updateCartData({}, dispatch);
            }
        );
    }

    _syncCartWithBE(dispatch) {
        // Need to get current cart from BE, update cart
        fetchQuery(CartQuery.getCartQuery(
            !isSignedIn() && this._getGuestQuoteId()
        )).then(
            ({ cartData }) => this._updateCartData(cartData, dispatch),
            () => {
                this._createEmptyCart(dispatch).then((data) => {
                    BrowserDatabase.setItem(data, GUEST_QUOTE_ID);
                    this._updateCartData({}, dispatch);
                    GoogleTagManager.getInstance().setGroupedProducts({});
                });
            }
        );
    }

    async reorder(dispatch, orderId) {
        if (isSignedIn()) {
            // This is logged in customer, no need for quote id
            this._syncCartWithBE(dispatch);
        }

        try {
            const reorderQuery = CartQuery.reorder(
                orderId
            );

            const { reorder: { success } } = await fetchMutation(reorderQuery);

            if (isSignedIn()) {
                // This is logged in customer, no need for quote id
                this._syncCartWithBE(dispatch);
            }

            //return this._updateCartData(cartData, dispatch);
        } catch ([{ message }]) {
            dispatch(showNotification('error', message));
            return Promise.reject();
        }
    }

    async addProductToCart(dispatch, options) { // check guest cart id
        const guestQuoteId = this._getGuestQuoteId();
        const {
            product,
            quantity,
            productOptionsData
        } = options;

        const {
            sku,
            type_id: product_type
        } = product;

        const {
            productOptions,
            productOptionsMulti
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                extension_attributes: getExtensionAttributes(
                    {
                        ...product,
                        productOptions,
                        productOptionsMulti
                    }
                )
            }
        };

        if (!guestQuoteId) {
            await this.createGuestEmptyCart(dispatch);
        }

        if (this._canBeAdded(options)) {
            try {
                const { saveCartItem: { cartData } } = await fetchMutation(CartQuery.getSaveCartItemMutation(
                    productToAdd, !isSignedIn() && this._getGuestQuoteId()
                ));

                return this._updateCartData(cartData, dispatch);
            } catch ([{ message }]) {
                dispatch(showNotification('error', message));
                return Promise.reject();
            }
        }

        return Promise.reject();
    }

    async applyCouponToCart(dispatch, couponCode) { // check guest cart id
        const guestQuoteId = this._getGuestQuoteId();

        if (!guestQuoteId) {
            await this.createGuestEmptyCart(dispatch);
        }

        try {
            const { applyCoupon: { cartData } } = await fetchMutation(CartQuery.getApplyCouponMutation(
                couponCode, !isSignedIn() && this._getGuestQuoteId()
            ));

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));
        } catch (error) {
            dispatch(showNotification('error', error[0].message));
        }
    }

    resetGuestCart(dispatch) {
        return this._updateCartData({}, dispatch);
    }

    // custom

    addGiftCardToCart(dispatch, input, quoteId) {
        return fetchMutation(CartQuery.addGiftCardToCard(input, quoteId))
        .then(
            ({ addGiftCardToCart: { cartData } }) => this._updateCartData(cartData, dispatch),
            ([{ message }]) => {
                dispatch(showNotification('error', message));
                return Promise.reject();
            }
        );
    }

}

export default new CartDispatcher();
