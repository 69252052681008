import { UPDATE_META } from './Meta.action';

export const updateEveryTime = [
    'title',
    'description',
    'keywords',
    'canonical_url',
    'robots',
    'og_image',
    'og_type'
];

export const filterData = (data) => {
    const updated = updateEveryTime.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
    }, {});

    return { ...data, ...updated };
};

export const initialState = {
    title: '',
    title_prefix: '',
    title_suffix: '',
    description: '',
    keywords: '',
    canonical_url: '',
    robots: '',
    og_image: ''
};

export const MetaReducer = (state = initialState, action) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(payload);

        return {
            ...state,
            ...filteredData
        };

    default:
        return state;
    }
};

export default MetaReducer;
