import { MenuQuery as MenuQuerySource } from 'SourceQuery/Menu.query';
import { Field } from 'Util/Query';
/**
 * Menu Query
 * @class MenuQuery
 */
export class MenuQuery extends MenuQuerySource {

    _getMenuItemFields() {
        return [
            'url',
            'icon',
            'title',
            'item_id',
            'position',
            'url_type',
            'parent_id',
            'is_active',
            'item_class',
            'category_id',
            'cms_page_identifier',
            'cat_LIO'
        ];
    }
}

export default new MenuQuery();
