import './PromotionPopup.style';

import {PureComponent} from 'react';

import Popup from 'Component/Popup';
import CmsBlock from 'Component/CmsBlock';
import Newsletter from 'Component/Newsletter';

import {PROMOTION_POPUP} from './PromotionPopup.config';

class PromotionPopup extends PureComponent {

    renderContent() {
        const {promotionConfig} = this.props;

        if (!promotionConfig?.block_id) {
            return null
        }

        return (
            <CmsBlock
                identifier={promotionConfig.block_id}
            />
        );
    }

    render() {
        const {handleClosePopup} = this.props;

        return (
            <Popup
                id={PROMOTION_POPUP}
                mix={{block: 'PromotionPopup'}}
                title={__('Promotions')}
                hidePopUp={handleClosePopup}
            >
                {this.renderContent()}
            </Popup>
        );
    }
}

export default PromotionPopup;
