import { lazy, Suspense } from 'react';
import SourceSearchField from 'SourceComponent/SearchField/SearchField.component';
import ClickOutside from 'Component/ClickOutside';
import { history } from 'Route';

import { SEARCH_CHAR_THRESHOLD } from 'Component/SearchOverlay/SearchOverlay.config';

export const SearchOverlay = lazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category" */
        'Component/SearchOverlay'
    )
);

class SearchField extends SourceSearchField {
    onSearchEnterPress = (e) => {
        if (e.key === 'Enter') {
            this.goToSearchResults();
        }
    };

    goToSearchResults() {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');

        if (searchCriteria.length > SEARCH_CHAR_THRESHOLD) {
            history.push(`/search/${ search }`);
            hideActiveOverlay();
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    }

    handleOnClickCloseButton = () => {
        this.onClearSearchButtonClick();
    }

    renderContent() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            isVisible
        } = this.props;

        return (
            <>
                <input
                    id="search-field"
                    ref={ this.searchBarRef }
                    block="SearchField"
                    elem="Input"
                    onFocus={ onSearchBarFocus }
                    onChange={ this.handleChange }
                    onKeyDown={ this.onSearchEnterPress }
                    value={ searchCriteria }
                    mods={ { isActive } }
                    autoComplete="off"
                    placeholder={ __('Search products') }
                />
                <button
                    block="SearchField"
                    elem="Close"
                    mods={{ isVisible: searchCriteria.length > 0 }}
                    onClick={ this.handleOnClickCloseButton }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.849" height="20.562" viewBox="0 0 18.849 20.562"><g data-name="Group 701" fill="#d8d8d8"><path data-name="Path 314" d="M18.731 3.547a.417.417 0 0 0-.308-.12h-4.139l-.937-2.236a1.855 1.855 0 0 0-.723-.844A1.891 1.891 0 0 0 11.567 0H7.283a1.89 1.89 0 0 0-1.058.348 1.855 1.855 0 0 0-.723.844l-.937 2.236H.431a.412.412 0 0 0-.428.428v.857a.412.412 0 0 0 .428.428h1.285v12.744a2.929 2.929 0 0 0 .629 1.894 1.885 1.885 0 0 0 1.513.783h11.136a1.866 1.866 0 0 0 1.513-.81 3.04 3.04 0 0 0 .629-1.921V5.14h1.285a.412.412 0 0 0 .428-.428v-.857a.418.418 0 0 0-.118-.308ZM7.082 1.86a.377.377 0 0 1 .228-.147h4.244a.378.378 0 0 1 .228.147l.643 1.566h-6Zm8.34 15.971a1.517 1.517 0 0 1-.094.542 1.207 1.207 0 0 1-.194.361c-.067.076-.114.114-.141.114H3.855c-.027 0-.073-.038-.14-.114a1.2 1.2 0 0 1-.194-.361 1.522 1.522 0 0 1-.094-.542V5.14h12v12.692Z"/><path data-name="Path 315" d="M5.569 16.279h.857a.412.412 0 0 0 .428-.428V8.14a.412.412 0 0 0-.428-.428h-.857a.412.412 0 0 0-.428.428v7.711a.412.412 0 0 0 .428.428Z"/><path data-name="Path 316" d="M8.996 16.279h.857a.412.412 0 0 0 .428-.428V8.14a.412.412 0 0 0-.428-.428h-.857a.412.412 0 0 0-.428.428v7.711a.412.412 0 0 0 .428.428Z"/><path data-name="Path 317" d="M12.423 16.279h.857a.412.412 0 0 0 .428-.428V8.14a.412.412 0 0 0-.428-.428h-.857a.412.412 0 0 0-.428.428v7.711a.412.412 0 0 0 .428.428Z"/></g></svg>
                </button>
                <button
                    block="SearchField"
                    elem="Button"
                    onClick={ () => this.goToSearchResults() }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M10.664 0a10.659 10.659 0 018.2 17.474l6.836 6.79a1.016 1.016 0 11-1.432 1.436l-6.845-6.8a10.658 10.658 0 01-11.98 1.058 1.016 1.016 0 011-1.77 8.628 8.628 0 10-3.28-3.26 1.016 1.016 0 01-1.764 1.006A10.667 10.667 0 0110.664 0z" /></svg>
                </button>
            </>
        );
    }

    render() {
        const {
            onSearchOutsideClick,
            searchCriteria,
            isVisible,
            isActive
        } = this.props;

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ onSearchOutsideClick }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderContent() }
                        <Suspense fallback={ this.renderOverlayFallback() }>
                            <SearchOverlay clearSearch={ this.clearSearch } searchCriteria={ searchCriteria } />
                        </Suspense>
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchField;
