import { UPDATE_BUY_TOGETHER } from './BuyTogether.action';

const initialState = {
    items: []
};

const BuyTogetherReducer = ( state = initialState, action ) => {
    const { type, items } = action;

    if (type !== UPDATE_BUY_TOGETHER) {
        return state
    }

    return {
        ...state,
        items
    }
}

export default BuyTogetherReducer;
