import { PureComponent } from 'react';
import Newsletter from 'Component/Newsletter';
import isMobile from 'Util/Mobile';
import Image from 'Component/Image';
import './NewsletterSection.style';
import newsletterImage from '../../../public/assets/images/newsletter.png';

class NewsletterSection extends PureComponent {
    renderNewsletterForm() {
        return (
            <div block="NewsletterSection" elem="Form">
                <Newsletter />
            </div>
        );
    }

    render() {
        const {
            isCheckout, isMenuPage, isAccount, isCart, isNewsletterPage, isMiercuriPage
        } = this.props;

        if (
            isCheckout
            || isMenuPage
            || (isAccount && isMobile.any())
            || (isCart && isMobile.any())
            || isNewsletterPage
            || isMiercuriPage
        ) {
            return null;
        }

        return (
            <div block="Section" mods={ { Newsletter: true } }>
                <div block="Section" elem="Title"><span>{ __('I want promotions and news by email') }</span></div>
                <div block="Section" elem="Content">
                    <div block="NewsletterSection">
                        <Image
                          src={ newsletterImage }
                          ratio="custom"
                        />
                        { this.renderNewsletterForm() }
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsletterSection;
