import './CallOverlay.style';

import { PureComponent } from 'react';

import Overlay from 'Component/Overlay';

import { CALL_OVERLAY_KEY } from './CallOverlay.config';

class CallOverlay extends PureComponent {
    renderCall(code) {
        switch(code) {
            case 'default':
                return (
                    <>
                        <strong block="CallOverlay" elem="Phone">+40 372 406 300</strong>
                        <span block="CallOverlay" elem="Timetable">L-V: 8:00 - 16:00</span>
                    </>
                )
            case 'en':
                return (
                    <>
                        <strong block="CallOverlay" elem="Phone">+40 372 406 333</strong>
                        <span block="CallOverlay" elem="Timetable">M-F: 10:00 - 14:00</span>
                    </>
                )
            case 'it':
                return (
                    <>
                        <strong block="CallOverlay" elem="Phone">+40 372 406 333</strong>
                        <span block="CallOverlay" elem="Timetable">L - V: 9:00 - 15:00 </span>
                    </>
                )
            default:
                return null;
        }
    }

    render() {
        const { code, onVisible } = this.props;

        return (
            <Overlay
              id={ CALL_OVERLAY_KEY }
              mix={ { block: 'CallOverlay' } }
              onVisible={ onVisible }
            >
              { this.renderCall(code) }
            </Overlay>
        );
    }
}

export default CallOverlay;
