export const UPDATE_CONFIG = 'UPDATE_CONFIG';

/** @namespace Store/Config/Action/updateConfig */
export const updateConfig = (config) => ({
    type: UPDATE_CONFIG,
    config
});

export const UPDATE_CONFIG_DEVICE = 'UPDATE_CONFIG_DEVICE';

/** @namespace Store/Config/Action/updateConfigDevice */
export const updateConfigDevice = (device) => ({
    type: UPDATE_CONFIG_DEVICE,
    device
});
