import { GET_REVIEWS, SET_REVIEWS } from './Reviews.action';

export const initialState = {
    reviews: [],
    isLoading: false
};

const ReviewsReducer = (state = initialState, action) => {
    const { type, reviews, status } = action;
    switch (type) {
    case GET_REVIEWS:
        return {
            ...state
        };
    case SET_REVIEWS:
        return {
            ...state,
            reviews,
            isLoading: status
        };
    default:
        return state;
    }
};

export default ReviewsReducer;
