import { CmsPageQuery as SourceCmsPageQuery } from 'SourceQuery/CmsPage.query';

export class CmsPageQuery extends SourceCmsPageQuery {
    _getPageFields() { // added black friday
        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords',
            'blackfriday',
            'og_image_url'

        ];
    }
}

export default new CmsPageQuery();
