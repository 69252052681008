import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_QUESTION_LIST, GET_PRODUCT_QUESTIONS } from './Question.action';

export const QUESTIONS = 'questions';

export const getFormattedDate = (rawDate = '') => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = value => (value < RADIX ? `0${value}` : value);

    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);

    return `${day}.${month}.${date.getFullYear()}`;
};

export const formatQuestions = questions => questions.reduce((acc, question) => {
    const { base_question_info } = question;
    const { created_at } = base_question_info;
    const formattedDate = getFormattedDate(created_at);

    return [
        ...acc,
        {
            ...question,
            base_question_info: {
                ...question.base_order_info,
                created_at: formattedDate
            }
        }
    ];
}, []);

const questionList = BrowserDatabase.getItem(QUESTIONS) || [];

export const initialState = {
    questionList,
    isLoading: !questionList.length
};

const QuestionReducer = (state = initialState, action) => {
    const {
        type,
        questionList,
        status
    } = action;

    switch (type) {
    case GET_QUESTION_LIST:

        return {
            ...state,
            isLoading: status,
            questionList
        };
    case GET_PRODUCT_QUESTIONS:
        return {
            ...state,
            questionList,
            isLoading: false
        };
    default:
        return state;
    }
};

export default QuestionReducer;
