import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        const fields = [
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            this._getPriceRangeField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getShortDescriptionField(),
            'new_from_date', // new field
            'new_to_date', // new field
            'special_from_date',
            'special_to_date',
            this._getTierPricesField(),
            this._getCartRuleSpecialPriceField(),
            'salable_quantity_oos',
            this._getDeepArFilterDataField()
        ];

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getReviewSummaryField(),
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                'brand_url', // new field
                'maintenance_scheme', // new field
                this._getDeliveryDates(), // new fields
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment(),
                this._getCartRuleSpecialPriceField(),
                this._getDeepArFilterDataField()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsListField(), // new field
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }

    _getReviewsListField() {
        return new Field('reviewsList(pageSize:5)')
            // eslint-disable-next-line no-magic-numbers
            // .addArgument('pageSize', 'Int!', 5)
            .addFieldList(this._getReviewListFields());
    }

    _getReviewListFields() {
        return [
            this._getReviewsField(),
            'total_pages'
        ];
    }
    _getReviewFields() {
        return [
            'review_id',
            'nickname',
            'title',
            'detail',
            'created_at',
            'tip_par',
            'tip_ten',
            'sex',
            'age',
            'verified_sale',
            this._getRatingVotesField()
        ];
    }

    _getDeliveryDates() {
        return new Field('delivery_dates')
            .addFieldList([this._getDeliveryDetails()]);
    }

    _getDeliveryDetails() {
        return new Field('details')
            .addFieldList(['carrier_type', 'delivery_day', 'delivery_date']);
    }

    subscribePriceAlert(input) {
        return new Field('subscribePriceAlert')
            .addArgument('input', 'subscribePriceAlertInput', input)
            .addFieldList(['status', 'message']);
    }

    _getReviewSummaryFields() {
        const { isSingleProduct } = this.options;

        const fields = [
            'rating_summary',
            'review_count'
        ]

        if (isSingleProduct) {
            fields.push(
                this._getReviewSummaryGraph(),
                'review_count_with_ratings'
            );
        }

        return fields;
    }

    _getReviewSummaryGraph() {
        return new Field('rating_graph')
            .addFieldList(this._getReviewSummaryGraphFields())
    }
    _getReviewSummaryGraphFields() {
        return [
            'rating',
            'score'
        ]
    }

    getBestsellers(categoryId, productsCount) {
        return new Field('getBestSellCategoryWidget')
            .addArgument('categoryId', 'Int', categoryId)
            .addArgument('itemsCount', 'Int', productsCount)
            .addField(this._getBestsellersFields())
    }
    _getBestsellersFields() {
        return new Field('widget_items')
            .addFieldList(this._getProductInterfaceFields());
    }

    _getCartRuleSpecialPriceFields() {
        return [
            'price',
            'startDate',
            'endDate',
            'sticker_text',
            'sticker_background_color',
            'sticker_text_color'
        ];
    }

    _getCartRuleSpecialPriceField() {
        return new Field('cart_rule_special_price').addFieldList(this._getCartRuleSpecialPriceFields());
    }

    _getDeepArFilterDataField() {
        return new Field('deepArFilter').addFieldList([ 'id', 'productSku', 'filterColor', 'filterSwatchUrl', 'filterBinaryPath', 'updatedAt']);
    }
}

export default new ProductListQuery();
