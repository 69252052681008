import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { history } from 'Route';
import { connect } from 'react-redux';
import InstaFeed from './InstaFeed.component';

export const mapStateToProps = state => ({
    code: state.ConfigReducer.code
});

export class InstaFeedContainer extends PureComponent {
    state = {
        instagram: [
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/01.jpg',
            },
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/02.jpg',
            },
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/03.jpg',
            },
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/04.jpg',
            },
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/05.jpg',
            },
            {
                name: 'Photo name alt tag',
                src: '/media/instagram/06.jpg',
            }
        ]
    };

    containerProps = () => {
        const { instagram } = this.state;
        const { code } = this.props;

        const {
            location: {
                pathname
            }
        } = history;
        const isHomepage = pathname === '/';

        return {
            instagram,
            isHomepage,
            code
        };
    };

    render() {
        return <InstaFeed { ...this.containerProps() } />;
    }
}

export default withRouter(connect(mapStateToProps)(InstaFeedContainer));
