import {
    UPDATE_PROMOTION
} from './Promotion.action';

export const initialState = {
    isLoading: true,
    promotion: []
};

const PromotionReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_PROMOTION:
        const { promotion } = action;
        return {
            ...state,
            isLoading: false,
            promotion: promotion[0]
        };
    default:
        return state;
    }
};

export default PromotionReducer;
