import SourceField from 'SourceComponent/Field/Field.component';
import Input from 'Component/Input';
import ClickOutside from 'Component/ClickOutside';
import Html from 'Component/Html';
import PropTypes from 'prop-types';
import { MixType } from 'Type/Common';

import {
    TEXT_TYPE,
    EMAIL_TYPE,
    NUMBER_TYPE,
    NUMBERTEXT_TYPE,
    TEXTAREA_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    CHECKBOX_TYPE,
    SELECT_TYPE,
    DATE_TYPE,
    TEL_TYPE
} from './Field.config';

export default class Field extends SourceField {
    static propTypes = { // added DATE_TYPE
        skipValue: PropTypes.bool,
        isControlled: PropTypes.bool,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
            TEXT_TYPE,
            EMAIL_TYPE,
            NUMBER_TYPE,
            NUMBERTEXT_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            DATE_TYPE,
            TEL_TYPE
        ]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        message: PropTypes.string,
        helpernotice: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        validation: PropTypes.arrayOf(PropTypes.string),
        rows: PropTypes.number,
        checked: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        selectOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            disabled: PropTypes.bool,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        })),
        isDisabled: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        onKeyPress: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        mix: MixType,
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        autocomplete: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        maxLength: PropTypes.number
    };

    onChangeTelephone = this.onChangeTelephone.bind(this);
    onChangeDate = this.onChangeDate.bind(this);
    getCorrectFormat = this.getCorrectFormat.bind(this);
    onKeyDown = this.onKeyDown.bind(this);

    onKeyDown(e) {
        if (e.keyCode === 8) {
            const { value } = this.state;
            if (value.slice(-1) === '/') {
                this.setState({ value: value.substr(0, value.length - 1) });
            }
        }
    }

    checkValue(str, max) {
        if (str.charAt(0) !== '0' || str === '00') {
            let num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) {
                num = 1;
            }
            str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? `0${ num}` : num.toString();
        }

        return str;
    }

    getCorrectFormat(value) {
        let date = value.replace(/\D/g, '/').split('/');
        if (date[0].length > 2) {
            date = date.reverse().join('/');
        } else {
            date = value;
        }

        return date;
    }

    onChangeDate(value) {
        let input = value.target.value;
        if (/\D\/$/.test(input)) {
            input = input.substr(0, input.length - 3);
        }
        const values = input.split('/').map(v => v.replace(/\D/g, ''));

        if (values[0]) {
            values[0] = this.checkValue(values[0], 31);
        }
        if (values[1]) {
            values[1] = this.checkValue(values[1], 12);
        }
        const output = values.map((v, i) => (v.length === 2 && i < 2 ? `${v }/` : v));
        const newValue = output.join('').substr(0, 14);
        this.setState({ value: newValue.replace(/\s/g, '') });
    }

    onChangeTelephone(event) {
        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(event);
        }

        return this.handleChange(event.target.value);
    }

    renderTextarea() {
        const {
            id,
            name,
            rows,
            formRef,
            isDisabled,
            maxLength,
            placeholder
        } = this.props;

        const { value } = this.state;

        return (
            <textarea
              ref={ formRef }
              id={ id }
              name={ name }
              rows={ rows }
              value={ value }
              disabled={ isDisabled }
              onChange={ this.onChange }
              onFocus={ this.onFocus }
              onClick={ this.onClick }
              maxLength={ maxLength }
              placeholder={ placeholder }
            />
        );
    }

    renderCheckbox() {
        const { checked } = this.state;

        return (
            <Input
              { ...this.props }
              type="checkbox"
              checked={ checked }
              onChange={ this.onChangeCheckbox }
            />
        );
    }

    renderRadioButton() {
        const { checked } = this.props;

        return (
            <Input
              { ...this.props }
              type="radio"
              checked={ checked }
              onChange={ this.onClick }
              onKeyPress={ this.onKeyPress }
            />
        );
    }

    renderTypeNumber() {
        const {
            min,
            max
        } = this.props;

        const { value } = this.state;

        return (
            <>
                <button
                  className="input-subtract"
                  disabled={ +value === min }
                  onClick={ () => this.handleChange(+value - 1) }
                >
                    <span>–</span>
                </button>
                <Input
                  // { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ e => this.handleChange(e.target.value, false) }
                  onKeyDown={ this.onKeyEnterDown }
                  value={ value }
                />
                <button
                  disabled={ +value === max }
                  onClick={ () => this.handleChange(+value + 1) }
                >
                    <span>+</span>
                </button>
            </>
        );
    }

    renderTypeTelephone() {
        const { value } = this.state;

        return (
            <Input
              { ...this.props }
              type="tel"
              onChange={ this.onChangeTelephone }
              onFocus={ this.onFocus }
              onClick={ this.onClick }
              value={ value }
            />
        );
    }

    renderTypeNumberText() {
        const { value } = this.state;

        return (
            <Input
              { ...this.props }
              type="number"
              onChange={ this.onChange }
              onFocus={ this.onFocus }
              onClick={ this.onClick }
              value={ value }
              min="0"
              max="99999999999"
            />
        );
    }

    renderTypeEmail() {
        const { value } = this.state;

        return (
            <Input
              { ...this.props }
              type="email"
              onChange={ this.onChange }
              onFocus={ this.onFocus }
              onClick={ this.onClick }
              value={ value }
            />
        );
    }

    renderSelectWithOptions() {
        const {
            name,
            id,
            selectOptions,
            formRef,
            placeholder,
            value,
            isDisabled
        } = this.props;

        const { isSelectExpanded: isExpanded } = this.state;

        if (!selectOptions) {
            throw new Error('Prop `selectOptions` is required for Field type `select`');
        }

        return (
            <ClickOutside onClick={ this.handleSelectExpandedExpand }>
                <div
                  block="Field"
                  elem="SelectWrapper"
                  onClick={ this.handleSelectExpand }
                  onKeyPress={ this.handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select drop-down"
                  aria-expanded={ isExpanded }
                >
                    <select
                      block="Field"
                      elem="Select"
                      mods={ { isExpanded } }
                      ref={ formRef }
                      name={ name }
                      id={ id }
                      disabled={ isDisabled }
                      tabIndex="0"
                      value={ this.state.value || value || '' }
                      onChange={ this.onChange }
                    >
                        { placeholder && <option value="" label={ placeholder } /> }
                        { selectOptions.map(({
                            id, value, disabled, label
                        }) => (
                                <option
                                  key={ id || label }
                                  id={ id }
                                  value={ value }
                                  disabled={ disabled }
                                >
                                    { label }
                                </option>
                        )) }
                    </select>
                    <ul
                      block="Field"
                      elem="SelectOptions"
                      role="menu"
                      mods={ { isExpanded } }
                    >
                        { selectOptions.map((options) => {
                            const { id, label } = options;

                            return (
                                <li
                                  block="Field"
                                  elem="SelectOption"
                                  mods={ { isExpanded } }
                                  key={ id || label }
                                  // added 'o' as querySelector does not work with
                                  // ids, that consist of numbers only
                                  id={ `o${id}` }
                                  role="menuitem"
                                  onClick={ () => this.handleSelectListOptionClick(options) }
                                  onKeyPress={ () => this.handleSelectListOptionClick(options) }
                                  tabIndex={ isExpanded ? '0' : '-1' }
                                >
                                    { label }
                                </li>
                            );
                        }) }
                    </ul>
                </div>
            </ClickOutside>
        );
    }

    renderTypeDate() {
        const { value } = this.state;

        return (
            <Input
              { ...this.props }
              type="text"
              onChange={ this.onChangeDate }
              onFocus={ this.onFocus }
              onClick={ this.onClick }
              value={ this.getCorrectFormat(value) }
              placeholder={__("dd/mm/yyyy")}
              onKeyDown={ this.onKeyDown }
              maxLength="10"
            />
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case DATE_TYPE:
            return this.renderTypeDate();
        case EMAIL_TYPE:
            return this.renderTypeEmail();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case NUMBERTEXT_TYPE:
            return this.renderTypeNumberText();
        case TEL_TYPE:
            return this.renderTypeTelephone();
        default:
            return this.renderTypeText();
        }
    }

    renderLabel() {
        const { id, label, validation } = this.props;
        const isRequired = validation.includes('notEmpty');
        if (!label) {
            return null;
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired } }
              htmlFor={ id }
            >
                <Html content={ label } />
            </label>
        );
    }

    renderHelper() {
        const { helpernotice } = this.props;

        if (!helpernotice) {
            return null;
        }

        return (
            <p block="Field" elem="Helper">
                { helpernotice }
            </p>
        );
    }

    render() {
        const { mix, type, message } = this.props;
        const { value } = this.state;

        return (
            <div
              block="Field"
              mods={ { type, hasError: !!message } }
              mix={ mix }
              className={ value ? 'label-active' : '' }
            >
                { this.renderInputOfType(type) }
                { this.renderLabel() }
                { this.renderHelper() }
                { this.renderMessage() }
            </div>
        );
    }
}
