import { PureComponent } from 'react';
import Form from 'Component/Form';
import Field from 'Component/Field';
import Html from 'Component/Html';
import './Newsletter.style';

class Newsletter extends PureComponent {
    renderGdprNotice() {
        const { showGdpr } = this.props;

        return (
            <div block="Newsletter" elem="Gdpr" style={ { visibility: showGdpr ? 'visible' : 'hidden' } }>
                <Html content={ __('I agree with the <a href="/page/privacy-policy" target="_blank">Privacy Policy</a>') } />
            </div>
        );
    }

    render() {
        const {
            email, buttonText, onNewsletterInputChange, onNewsletterSubmitSuccess, onNewsletterInputFocus
        } = this.props;

        return (
            <div block="Newsletter">
                <Form
                  onSubmitSuccess={ onNewsletterSubmitSuccess }
                >
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      value={ email }
                      onChange={ onNewsletterInputChange }
                      placeholder={ __('email address') }
                      validation={ ['notEmpty', 'email'] }
                      onFocus={ onNewsletterInputFocus }
                    />
                    <button block="Button">{ buttonText ? buttonText : __('Subscribe to newsletter') }</button>
                </Form>
                { this.renderGdprNotice() }
            </div>
        );
    }
}

export default Newsletter;
