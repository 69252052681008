import {
    GET_PROMOTIONS
} from './Promotions.action';

export const initialState = {
    isLoading: true,
    promotions: []
};

const PromotionsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PROMOTIONS:
        const { promotions } = action;
        return {
            ...state,
            isLoading: false,
            promotions
        };
    default:
        return state;
    }
};

export default PromotionsReducer;
