import SourceSomethingWentWrong from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

import ContentWrapper from 'Component/ContentWrapper';

export class SomethingWentWrong extends SourceSomethingWentWrong {
    render() {
        const { onButtomClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    <a
                      href="/"
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onButtomClick }
                    >
                        { __('Back to homepage') }
                    </a>
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrong;
