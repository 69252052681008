import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history } from 'Route';
import { BOTTOM_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import Footer from './Footer.component';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';

export const mapStateToProps = state => ({
    bottomTabName: state.NavigationReducer[BOTTOM_NAVIGATION_TYPE].navigationState.name
});

export class FooterContainer extends PureComponent {
    static propTypes = {
        bottomTabName: PropTypes.string
    };

    static defaultProps = {
        bottomTabName: ''
    };

    containerProps = () => {
        const { bottomTabName } = this.props;

        const {
            location: {
                pathname
            }
        } = history;
        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            bottomTabName,
            isCheckout
        };
    };

    render() {
        return <Footer { ...this.containerProps() } />;
    }
}

export default withRouter(connect(mapStateToProps)(FooterContainer));
