import {
    AddToCartContainer as SourceAddToCartContainer,
    mapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showNotification } from 'Store/Notification/Notification.action';

import Event, { EVENT_GTM_PRODUCT_ADD_TO_CART } from 'Util/Event';

const CartDispatcher = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */'Store/Cart/Cart.dispatcher');
const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    removeFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

export class AddToCartContainer extends SourceAddToCartContainer {
    addGroupedProductToCart() { // added GTM
        const {
            product,
            product: { items },
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                quantity
            });
        })).then(
            () => {
                Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                    product: {
                        ...newProduct,
                        quantities: quantity
                    },
                    isGrouped: true
                });

                this.afterAddToCart()
            },
            () => this.resetLoading()
        );
    }

    addConfigurableProductToCart() { // added GTM
        const {
            product,
            quantity,
            addProduct,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            quantity,
            productOptionsData
        }).then(
            () => {
                Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                    product,
                    quantity,
                    configurableVariantIndex
                });

                this.afterAddToCart()
            },
            () => this.resetLoading()
        );
    }

    addSimpleProductToCart() { // added GTM
        const {
            product,
            quantity,
            addProduct,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            productOptionsData
        }).then(
            () => {
                Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                    product,
                    quantity
                });

                this.afterAddToCart()
            },
            () => this.resetLoading()
        );
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            hideActiveOverlay
        } = this.props;

        showNotification('success', __('Product added to cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });

        hideActiveOverlay();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
