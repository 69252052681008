import SourceCookiePopup from 'SourceComponent/CookiePopup/CookiePopup.component';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';

class CookiePopup extends SourceCookiePopup {
    renderCookieLink() {
        return null;
    }

    renderCookieText() {
        const { cookieText } = this.props;

        return (
            <div block="CookiePopup" elem="Content">
                <Html content={ cookieText } />
            </div>
        );
    }

    render() {
        const { cookieText, location: { pathname, state } } = this.props;
        const { isAccepted } = this.state;

        const isProductPage = state?.hasOwnProperty('product');

        if (pathname.indexOf('checkout') !== -1) {
            return null;
        }

        if (!cookieText || isAccepted) {
            return null;
        }

        return (
            <div block="CookiePopup" mods={{ isProductPage }}>
                <ContentWrapper
                  label="Cookie popup"
                  mix={ { block: 'CookiePopup', elem: 'Wrapper' } }
                  wrapperMix={ { block: 'CookiePopup', elem: 'ContentWrapper' } }
                >
                    { this.renderCookieText() }
                    <div block="CookiePopup" elem="CTA">
                        <button block="Button" onClick={ this.acceptCookies }>
                            { __('Accept') }
                        </button>
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

export default CookiePopup;
