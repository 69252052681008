export const GET_REVIEWS = 'GET_REVIEWS';
export const SET_REVIEWS = 'SET_REVIEWS';

export const getReviews = reviewsList => ({
    type: GET_REVIEWS,
    reviewsList
});

export const setReviews = (reviews, status) => ({
    type: SET_REVIEWS,
    reviews,
    status
});
