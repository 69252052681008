import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { history } from 'Route';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import NewsletterSection from './NewsletterSection.component';

export class NewsletterSectionContainer extends PureComponent {
    containerProps = () => {
        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isMenuPage = pathname === '/menu';
        const isAccount = pathname.includes('/my-account');
        const isCart = pathname === '/cart';
        const isNewsletterPage = pathname === '/newsletter';
        const isMiercuriPage = pathname ==='/miercuri';

        return {
            isCheckout,
            isMenuPage,
            isAccount,
            isCart,
            isNewsletterPage,
            isMiercuriPage
        };
    };

    render() {
        return <NewsletterSection { ...this.containerProps() } />;
    }
}

export default withRouter(NewsletterSectionContainer);
