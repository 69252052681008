import { LinkContainer as SourceLinkContainer } from 'SourceComponent/Link/Link.container';
import { connect } from 'react-redux';

import { appendWithStoreCode } from 'Util/Url';

export const mapStateToProps = (state) => ({
    baseLinkUrl: state.ConfigReducer.base_link_url
});

export class LinkContainer extends SourceLinkContainer {
    getTo() {
        const { to } = this.props;

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^https?:\/\//.test(to)) {
                return to;
            }

            // in case this URL is internal, do not append store
            if (/^#/.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const { pathname } = to;

        return {
            pathname: appendWithStoreCode(pathname),
            ...to
        };
    }
}

export default connect(mapStateToProps)(LinkContainer);
