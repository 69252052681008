import {
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import {connect} from "react-redux";
import { history } from 'Route';
import { isSignedIn } from 'Util/Auth';

import {
    TYPE_CATEGORY,
    TYPE_PRODUCT
} from 'Route/UrlRewrites/UrlRewrites.config';

import { LOGGED_IN_ONLY_PAGES } from 'Route/UrlRewrites/LoggedInOnly.config';

const UrlRewritesDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/UrlRewrites/UrlRewrites.dispatcher'
    );

export const mapStateToProps = (state) => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isLoading: state.UrlRewritesReducer.isLoading,
    requestedUrl: state.UrlRewritesReducer.requestedUrl,
    promoData: state.ConfigReducer.promoData,
    loggedInOnlyPages: state.ConfigReducer.cms_LIO
});

export const mapDispatchToProps = (dispatch) => ({
    requestUrlRewrite: (urlParam) => {
        UrlRewritesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, {
                // TODO: this seems to break when switched to disabled-url-stores
                urlParam: urlParam.replace(new RegExp(window.storeRegexText), '')
            })
        );
    }
});

export class UrlRewritesContainer extends SourceUrlRewritesContainer {

    handleRedirectForLoggedInOnlyContent() {
        if (isSignedIn()) { return; }

        const { promoData, type, cms_LIO } = this.containerProps();
        const promoDataProp = type === TYPE_CATEGORY ? 'LIO_categories': type === TYPE_PRODUCT ? 'LIO_products' : false;

        // TODO check what this is used for and if it can be extended to use the automatic redirect mechanism
        if (promoDataProp && promoData[promoDataProp]?.includes(location?.pathname)) {
            history.replace('/my-account/login');
        } else if (LOGGED_IN_ONLY_PAGES?.includes(location?.pathname)) {
            history.replace('/my-account/login');
        }
    }

    componentDidMount() {
        this.initialUrl = location.pathname;

        this.handleRedirectForLoggedInOnlyContent();
    }

    componentDidUpdate() {
        const { isLoading } = this.props;

        /**
         * If the latest requested URL rewrite is not related
         * to the current location, and the URL rewrites are not loading
         * request new URL rewrite.
         */
        if (this.getIsLoading() && !isLoading) {
            this.requestUrlRewrite();
        }

        this.handleRedirectForLoggedInOnlyContent();
    }

    containerProps = () => {
        const { promoData } = this.props;
        return {
            promoData,
            type: this.getType(),
            props: this.getProps()
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
