import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';
import { isSignedIn } from 'Util/Auth';

export class CartQuery extends SourceCartQuery {
    getShippingCarrier(countryId, quoteId) {
        return new Field('getCarrier')
            .addArgument('filters', 'CarrierFilterInput!', `{ cartId: ${quoteId}, country_id: { eq: ${countryId} }}`)
            .addFieldList(this._getShippingCarrierFields());
    }

    _getShippingCarrierFields() {
        return [
            'shipping_method_label',
            'delivery_days'
        ];
    }

    getShippingEstimateMutation(countryId, carrierLabel, quoteId) {
        const othersArgs = {
            country_code: countryId,
            shipping_method_code: 'matrixrate',
            shipping_method_label: carrierLabel
        };
        const mutation = new Field('getCartForCustomerSum')
            .addArgument('input', 'SetShippingTCart', othersArgs)

            .addFieldList([this.getCartQuery(quoteId)]);

        if (!isSignedIn()) {
            othersArgs.guestCartId = quoteId;
        }

        return mutation;
    }

    _getCartTotalsFields() {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'is_virtual',
            'shipping_amount',
            'total_discount_amount',
            'last_payment_method',
            'last_shipping_method',
            this._getCartItemsField(),
            this._getCartDiscounts(),
            this._getFreeGift(),
            'cart_free_gift_items',
            'payment_fee',
            'earn_point',
            this._getBssSpentPointField()
        ];
    }

    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'price_incl_tax',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            this._getCustomizableOptionsFields(),
            this._getBundleOptionsField(),
            this._getProductField()
        ];
    }

    _getCartDiscounts() {
        return new Field('discount_data')
            .addFieldList(this._getCartDiscountsFields());
    }

    _getCartDiscountsFields() {
        return [
            'label',
            'amount'
        ];
    }

    getCartTestimonials() {
        return new Field('getTestimonials')
            .addArgument('limit', 'Int', 1)
            .addArgument('random', 'Int', 1)
            .addField(this._getCartTestimonialsFields());
    }

    _getCartTestimonialsFields() {
        return new Field('items')
            .addFieldList(['message', 'name']);
    }

    // Gift card
    getGiftCardProduct() {
        return new Field('getGiftCardProduct')
        .addFieldList(this._getGiftCardProductFields());
    }
    _getGiftCardProductFields() {
        return [
            'title',
            'sku',
            'description',
            'short_description',
            this._getGiftCardProductAmounts(),
            this._getGiftCardProductImage()
        ]
    }
    _getGiftCardProductAmounts() {
        return new Field('amounts')
            .addFieldList(['value']);
    }
    _getGiftCardProductImage() {
        return new Field('image')
        .addFieldList(['url']);
    }

    addGiftCardToCard(fields, quoteId) {
        return new Field('addGiftCardToCart')
            .addArgument('input', 'addGiftInput!', fields)
            .addFieldList(this._getSaveCartItemFields(quoteId));
    }

    reorder(orderId) {
        console.log('CartQuery reorder', orderId);
        return new Field('reorder')
            .addArgument('input', 'ReorderInput!', { order_id: orderId })
            .addFieldList(['success', 'message']);
    }

    // Free gift product
    _getFreeGift() {
        return new Field('free_gift_items')
            .addFieldList(this._getFreeGiftFields());
    }
    _getFreeGiftFields() {
        return [
            'name',
            'id',
            'sku',
            // 'stock_status',
            'type_id',
            'url',
            this._getPriceRangeField(),
            this._getProductSmallField(),
            this._getProductThumbnailField(),
            this._getReviewSummaryField()
        ]
    }

    // Price
    _getPriceRangeField() {
        return new Field('price_range')
            .addFieldList(this._getPriceRangeFields());
    }
    _getPriceRangeFields() {
        return [
            this._getMinimalPriceField()
        ];
    }
    _getMinimalPriceField() {
        return new Field('minimum_price')
            .addFieldList(this._getMinimalPriceFields());
    }
    _getMinimalPriceFields() {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getRegularPriceField()
        ];
    }
    _getDiscountField() {
        return new Field('discount')
            .addField('amount_off')
            .addField('percent_off');
    }
    _getFinalPriceField() {
        return new Field('final_price')
            .addField('currency')
            .addField('value');
    }
    _getRegularPriceField() {
        return new Field('regular_price')
            .addField('currency')
            .addField('value');
    }

    // Thumbnail
    _getProductSmallField() {
        return new Field('small_image')
            .addFieldList(this._getProductSmallFields());
    }
    _getProductSmallFields() {
        return this._getProductThumbnailFields();
    }
    _getProductThumbnailFields() {
        return [
            'path',
            'url'
            // 'label'
        ];
    }
    _getProductThumbnailField() {
        return new Field('thumbnail')
            .addFieldList(this._getProductThumbnailFields());
    }

    // Review
    _getReviewSummaryField() {
        return new Field('review_summary')
            .addFieldList(this._getReviewSummaryFields());
    }
    _getReviewSummaryFields() {
        return [
            'rating_summary',
            'review_count'
        ];
    }

    updateQuotePayment(payment_method, guestCartId) {
        const mutation = new Field('updateQuotePayment')
            .addArgument('payment_method', 'String', payment_method)
            .addField(this.getCartQuery(guestCartId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    _getBssSpentPointField() {
           return new Field('spent_point').addFieldList(['as_money_amount','point_amount'])
    }
}

export default new CartQuery();
