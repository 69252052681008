import SourceLink from 'SourceComponent/Link/Link.component';
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

export class Link extends SourceLink {
    scrollToElement = (e) => {
        const {
            to: cssIdentifier,
            onClick
        } = this.props;

        const elem = document.querySelector(
            cssIdentifier !== '#' ? cssIdentifier : 'body'
        );

        event.preventDefault();

        let offset = 0;
        if (window.innerWidth >= 1366) {
            offset = 100;
        } else if (window.innerWidth <= 767) {
            offset = 65;
        }

        window.scrollTo({
            top: elem.getBoundingClientRect().top + window.scrollY - offset,
            behavior: 'smooth'
        });

        elem.focus();

        onClick(e);
    };

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            ...props
        } = this.props;

        if (!to) {
            return (
                <div
                  { ...props }
                  className={ `${className } ${ stringify(bemProps)}` }
                >
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ to }
                  // eslint-disable-next-line react/forbid-component-props
                  className={ `${className } ${ stringify(bemProps)}` }
                >
                    { children }
                </a>
            );
        }

        if (/^https?:\/\//.test(to)) {
            return (
                <a
                  { ...props }
                  href={ to }
                  // eslint-disable-next-line react/forbid-component-props
                  className={ `${className } ${ stringify(bemProps)}` }
                >
                    { children }
                </a>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              // eslint-disable-next-line react/forbid-component-props
              className={ `${className } ${ stringify(bemProps)}` }
            >
                { children }
            </RouterLink>
        );
    }
}

export default Link;
