import React, { PureComponent } from 'react';
import Header from 'Component/Header';
import Menu from 'Component/Menu';

import './HeaderSticky.style';

class StickyHeader extends PureComponent {
    render() {
        const { isSticky, isCheckout } = this.props;
        return (
        <div block="HeaderSticky" mods={ { isSticky: isSticky && !isCheckout } }>
            <div block="HeaderSticky" elem="Container">
                <Header isSticky={ isSticky } />
                <Menu />
            </div>
        </div>
        );
    }
}

export default StickyHeader;
