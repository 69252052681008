import { withRouter } from 'react-router';
import { HeaderContainer as SourceHeaderContainer } from 'SourceComponent/Header/Header.container';
import { connect } from 'react-redux';
import isMobile from 'Util/Mobile';
import { toggleOverlayByKey, hideActiveOverlay, hideActiveOverlayExcept } from 'Store/Overlay/Overlay.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { history } from 'Route';
import BrowserDatabase from 'Util/BrowserDatabase';
import { isSignedIn } from 'Util/Auth';
import { closeMyAccountPopover }  from 'Store/MyAccount/MyAccount.action';

import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { TWO_DAYS_IN_SECONDS, NEWSLETTER_POPUP } from 'Component/NewsletterPopup/NewsletterPopup.config';
import {  PROMOTION_POPUP, ONE_HOUR_IN_SECONDS } from 'Component/PromotionPopup/PromotionPopup.config';
import { CALL_OVERLAY_KEY } from 'Component/CallOverlay/CallOverlay.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    CALL,
    CUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT,
    CART,
    CART_OVERLAY,
    MENU,
    POPUP,
    SEARCH
} from './Header.config';

import { getAuthorizationToken } from 'Util/Auth';

export * from 'SourceComponent/Header/Header.container';

const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = state => ({
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    cartTotals: state.CartReducer.cartTotals,
    isLoading: state.ConfigReducer.isLoading,
    activeOverlay: state.OverlayReducer.activeOverlay,
    wishlistCount: Object.keys(state.WishlistReducer.productsInWishlist).length,
    code: state.ConfigReducer.code,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    freeShipping: state.ConfigReducer.freeShipping,
    popoverClosedByUser: state.MyAccountReducer.popoverClosedByUser,
    promotionConfig: state.ConfigReducer.getPopupInformation
});

const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    hideActiveOverlayExcept: (overlayKey) => dispatch(hideActiveOverlayExcept(overlayKey)),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    logout: () => MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.logout(true, dispatch)),
    closePopover: () => dispatch(closeMyAccountPopover())
});

class HeaderContainer extends SourceHeaderContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onCallButtonClick: this.onCallButtonClick.bind(this),
        onCallOutsideClick: this.onCallOutsideClick.bind(this),
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
        onCloseMenuButtonClick: this.onCloseMenuButtonClick.bind(this),
        onPopoverClosedByUser: this.onPopoverClosedByUser.bind(this)
    };

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            isLoading,
            wishlistCount,
            isSticky,
            code,
            freeShipping,
            isSignedIn,
            popoverClosedByUser
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            wishlistCount,
            isSticky,
            code,
            freeShipping,
            isSignedIn,
            popoverClosedByUser
        };
    };

    onRouteChanged(history) {
        const { isSignedIn, logout , hideActiveOverlay} = this.props;
        const _isSignedIn = !!getAuthorizationToken();

        if (!_isSignedIn && isSignedIn) {
            logout();
        }

        if (!isMobile.any() && !isMobile.tablet()) {
            return this.handleDesktopRouteChange(history);
        }

        this.hidePromotionPopupOverlay();
        return this.handleMobileUrlChange(history);
    }

    onCallButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            setNavigationState
        } = this.props;

        if (name === CALL) {
            this.goToDefaultHeaderState();
        } else {
            setNavigationState({ name: CALL });
        }
        showOverlay(CALL_OVERLAY_KEY);
    }

    onCallOutsideClick() {
        const {
            hideActiveOverlay,
            navigationState: { name }
        } = this.props;

        if (name !== CALL) {
            return;
        }

        hideActiveOverlay();
        this.goToDefaultHeaderState();
    }

    onWishlistButtonClick() {
        const { showNotification } = this.props;
        if (isSignedIn()) {
            history.push({ pathname: '/my-account/my-wishlist' });
        } else {
            showNotification('info', __('Go to your account in order to add Favorite products'));
        }
    }

    onMyAccountButtonClick() {
        const {
            showOverlay,
            setNavigationState,
            navigationState: { name }
        } = this.props;

        if (isSignedIn()) {
            history.push({ pathname: '/my-account/dashboard' });
            return;
        }

        if (name === CUSTOMER_ACCOUNT || name === CUSTOMER_SUB_ACCOUNT) {
            this.goToDefaultHeaderState();

            this.setState({ shouldRenderAccountOverlay: false });
            this.setState({ showMyAccountLogin: false });
        } else {
            this.setState({ shouldRenderAccountOverlay: true });
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            setNavigationState({ name: CUSTOMER_ACCOUNT, title: 'Sign in' });

            this.setState({ showMyAccountLogin: true });
        }
    }

    onMyAccountOutsideClick() {
        const {
            hideActiveOverlay,
            navigationState: { name }
        } = this.props;

        if (
            isMobile.any()
            || !(name === CUSTOMER_ACCOUNT || name === CUSTOMER_SUB_ACCOUNT)
            || [CART_OVERLAY, MENU, POPUP].includes(name)
            || (!isMobile.any() && name === SEARCH)
        ) {
            return;
        }
        this.goToDefaultHeaderState();
        hideActiveOverlay();
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name }
        } = this.props;

        if (!isMobile.any()) {
            this.setState({ shouldRenderCartOverlay: true });

            showOverlay(CART_OVERLAY);
            return;
        }

        if (name === CART) {
            this.goToDefaultHeaderState();
        }

        history.push(`/${ CART }`);
    }

    onMinicartOutsideClick() {
        const {
            hideActiveOverlay,
            navigationState: { name }
        } = this.props;

        if (isMobile.any() || name !== CART_OVERLAY) {
            return;
        }

        this.goToDefaultHeaderState();
        hideActiveOverlay();
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            showOverlay,
            navigationState: { name }
        } = this.props;

        if (
            (!isMobile.any() && name === SEARCH)
            || (isMobile.any() && name !== MENU)
        ) {
            return;
        }

        showOverlay(SEARCH);

        setNavigationState({
            name: SEARCH
        });
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }

    hidePromotionPopupOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        if (activeOverlay === PROMOTION_POPUP) {
            hideActiveOverlay();
        }
    }

    onCloseMenuButtonClick() {
        const { goToPreviousNavigationState, hideActiveOverlay } = this.props;

        // dont go back when exit from search overlay
        if (history.location.pathname === '/menu') {
            history.goBack()
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onCloseButtonClick(e) {
        const {hideActiveOverlay, goToPreviousNavigationState, activeOverlay, promotionConfig} = this.props;
        const { navigationState: { onCloseClick } } = this.props;
        this.setState({ searchCriteria: '' });

        if (onCloseClick) {
            onCloseClick(e);
        }

        if (activeOverlay === 'newsletter_popup') {
            BrowserDatabase.setItem(true, NEWSLETTER_POPUP, TWO_DAYS_IN_SECONDS);
        }

        let lifetime = ONE_HOUR_IN_SECONDS;

        if (!promotionConfig?.popup_lifetime) {
            lifetime = promotionConfig.popup_lifetime;
        }

        if (activeOverlay === 'promotion_popup') {
            BrowserDatabase.setItem(true, PROMOTION_POPUP, lifetime);
        }

        hideActiveOverlay();
        goToPreviousNavigationState();
    }

    onPopoverClosedByUser() {
        const { closePopover } = this.props;
        closePopover();
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
