import SourceStoreSwitcher from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

class StoreSwitcher extends SourceStoreSwitcher {
    render() {
        const {
            storeList,
            currentStoreCode
        } = this.props;

        if (storeList.length <= 1) {
            return null;
        }

        const newStoreList = storeList.filter(store => store.value !== currentStoreCode);

        return (
            <div block="StoreSwitcher">
                { newStoreList.map(this.renderStoreList) }
            </div>
        );
    }
}

export default StoreSwitcher;
