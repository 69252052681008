import SourceWidgetFactory from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import { lazy } from 'react';

const ProductListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/ProductListWidget'));
const NewProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/NewProducts'));
const HomeSlider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SliderWidget'));
const CategoryListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/CategoryListWidget'));
const CmsCounter = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/CmsCounter'));

import {
    CATALOG_PRODUCT_LIST,
    FARMEC_CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS,
    SLIDER,
    CATEGORY_WIDGET, CMS_COUNTER
} from './WidgetFactory.config';

export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback
        },
        [NEW_PRODUCTS]: {
            component: NewProducts
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [FARMEC_CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [CATEGORY_WIDGET]: {
            component: CategoryListWidget
        },
        [CMS_COUNTER]: {
            component: CmsCounter
        }
    };
}

export default WidgetFactory;
