import SourceBreadcrumbs from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import ContentWrapper from 'Component/ContentWrapper';

import { appendWithStoreCode } from 'Util/Url';

export class Breadcrumbs extends SourceBreadcrumbs {
    render() {
        const { breadcrumbs, areBreadcrumbsVisible } = this.props;

        if (
            !areBreadcrumbsVisible
            || location.pathname === appendWithStoreCode('/')
            || location.pathname === '/'
        ) {
            return null;
        }

        const breadcrumbsWithHome = [
            ...breadcrumbs,
            {
                url: '/',
                name: 'Farmec'
            }
        ]

        return (
            <ContentWrapper mix={ { block: 'Breadcrumbs' } } label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbsWithHome)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
