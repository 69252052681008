export const GET_QUESTION_LIST = 'GET_QUESTION_LIST';
export const GET_PRODUCT_QUESTIONS = 'GET_PRODUCT_QUESTIONS';

export const getQuestionList = (questionList, status) => ({
    type: GET_QUESTION_LIST,
    questionList,
    status
});

export const getProductQuestions = questionList => ({
    type: GET_PRODUCT_QUESTIONS,
    questionList,
    status: false
});
