import { connect } from 'react-redux';

import {
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer,
    mapDispatchToProps
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';

import SomethingWentWrong from './SomethingWentWrong.component';

export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    containerFunctions = {
        onButtomClick: this.onButtomClick.bind(this)
    };

    onButtomClick() {
        const { onClick } = this.props;

        localStorage.clear();

        onClick();
    }

    render() {
        return (
            <SomethingWentWrong
              { ...this.containerFunctions }
              { ...this.props }
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(SomethingWentWrongContainer);
