import './Menu.style';

import { PureComponent } from 'react';

import Link from 'Component/Link';
import { MenuType } from 'Type/Menu';
import TextPlaceholder from 'Component/TextPlaceholder';
import media from 'Util/Media';
import Image from 'Component/Image';

class Menu extends PureComponent {
    renderIcon(icon) {
        if (!icon) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'Menu', elem: 'Image' } }
              src={ icon && media(icon) }
              ratio="square"
            />
        );
    }

    renderItem(item, depth = 0) {
        const { activeMenuItemsStack, onFirstLevelHover, closeMenu } = this.props;

        const {
            item_id, title, children, url, icon, item_class
        } = item;

        const childrenArray = Object.values(children || {});
        const hasChildren = !!childrenArray.length;
        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <li
              key={ item_id }
              block="Menu"
              elem="Item"
              mods={ { depth, hasChildren, isHovered } }
              // eslint-disable-next-line react/forbid-dom-props
              className={ item_class }
            >
                { title && url !== '#'
                    ? (
                        <Link
                            to={ url }
                            onClick={ closeMenu }
                            onMouseEnter={ (depth === 0) ? () => onFirstLevelHover(item_id) : null }
                        >
                            { depth !== 0 && this.renderIcon(icon) }
                            { title !== 'Banner' && <span>{ title }</span> }
                        </Link>
                    )
                    : (
                        <p
                            onMouseEnter={ (depth === 0) ? () => onFirstLevelHover(item_id) : null }
                        >
                            { depth !== 0 && this.renderIcon(icon) }
                            { title !== 'Banner' && <span><TextPlaceholder content={ title } /></span> }
                        </p>
                    )
                }
                { hasChildren && this.renderChildren(childrenArray, depth + 1, item_class) }
            </li>
        );
    }

    renderChildren(children, depth, parent_class) {
        const sortedChildren = children.sort((a, b) => a.position - b.position);
        const renderSortedChildren = (arr = sortedChildren) => (
            <ul block="Menu" elem="Wrapper" mods={ { depth } }>
                { arr.map(child => this.renderItem(child, depth)) }
            </ul>
        );

        if (depth === 3 || parent_class.includes('menu-flat')) {
            const chunks = Array.from({ length: Math.ceil(sortedChildren.length / 13) }, (v, i) => sortedChildren.slice(i * 13, i * 13 + 13));

            return (
                <div block="Menu" elem="Columns">
                    { chunks.map((chunk, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={ index } block="Menu" elem="Column">
                            { renderSortedChildren(chunk) }
                        </div>
                    )) }
                </div>
            );
        }

        return renderSortedChildren();
    }

    render() {
        const { isCheckout, menu, closeMenu } = this.props;

        const isLoaded = !!Object.keys(menu).length;
        const placeholderMenu = {
            1: { item_id: '1', position: 1 },
            2: { item_id: '2', position: 2 },
            3: { item_id: '3', position: 3 },
            4: { item_id: '4', position: 4 },
            5: { item_id: '5', position: 5 },
            6: { item_id: '6', position: 6 }
        };

        if (isCheckout) {
            return null;
        }
        const sortByPosAndItemId = (a, b) => {
            if(a.position !== undefined && b.position !== undefined) {
                return a.position - b.position;
            }

            if(a.position === undefined && b.position !== undefined) {
                return 1;
            }

            if(a.position !== undefined && b.position === undefined) {
                return -1;
            }

            return a.item_id && b.item_id ? a.item_id - b.item_id : -1;
        };

        return (
            <nav block="Menu">
                <div block="Menu" elem="Container">
                    <ul
                      block="Menu"
                      elem="List"
                      onMouseLeave={ closeMenu }
                    >
                        { Object.values(isLoaded ? menu : placeholderMenu).sort(sortByPosAndItemId).map(item => this.renderItem(item)) }
                    </ul>
                </div>
            </nav>
        );
    }
}

Menu.propTypes = {
    menu: MenuType.isRequired
};


export default Menu;
