import SourcePopup from 'SourceComponent/Popup/Popup.component';

class Popup extends SourcePopup {
    hidePopUp = () => {
        const { hideActiveOverlay, goToPreviousNavigationState, hidePopUp } = this.props;
        const isVisible = this.getIsVisible();
        if (isVisible) {
            if (hidePopUp) {
                hidePopUp();
            }
            hideActiveOverlay();
            goToPreviousNavigationState();
        }
    };
}

export default Popup;
