import { Field } from 'Util/Query';

export class NewsletterQuery {
    getAddNewsletterMutation(fields) {
        return new Field('addNewsletter')
            .addArgument('input', 'NewsletterInput!', fields)
            .addFieldList(this._getAddNewsletterFields());
    }

    _getAddNewsletterFields() {
        return [
            'type',
            'message'
        ];
    }

    getData() {
        return new Field('getNewsletterPageData')
            .addFieldList(this.getDataFields());
    }

    getDataFields() {
        return [
            'is_active',
            'banner_image',
            'banner_link',
            'promotion_conditions',
            'conditions_expanded',
            'title',
            'products_widget'
        ];
    }
}

export default new NewsletterQuery();
