import { getStore } from 'Store';

/* eslint-disable import/prefer-default-export */
import currencyMap from './Price.config';

export const formatCurrency = (currency = 'RON') => currencyMap[currency];

/**
 * Calculate final price
 * @param {Number} discount discount percentage
 * @param {Number} min minimum price
 * @param {Number} reg regular price
 * @return {Number} final price
 */
export const calculateFinalPrice = (discount, min, reg) => (discount ? min : reg);

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 */
export const roundPrice = (price) => {
    const { ConfigReducer: { code } } = getStore().getState();
    const result = parseFloat(price).toFixed(2);

    if (code === 'en') {
        return result;
    }

    return result.replace('.', ',');
};

const withoutTime = (date) => {
    let newDate = new Date(date);
    newDate.setHours(0,0,0,0);
    return newDate;
}

const getHasActiveCartRuleSpecialPrice = (cartRuleSpecialPriceData) => {
    if (cartRuleSpecialPriceData?.startDate && cartRuleSpecialPriceData?.endDate && cartRuleSpecialPriceData?.price) {
        const today = withoutTime(new Date());
        const startDate = withoutTime(cartRuleSpecialPriceData.startDate);
        const endDate = withoutTime(cartRuleSpecialPriceData.endDate);

        return today >= startDate && today <= endDate;
    }
    return false;
}

export const calculateCartRuleSpecialPrice = (cartRuleSpecialPriceData) => {
    const hasActiveCartRuleSpecialPrice = getHasActiveCartRuleSpecialPrice(cartRuleSpecialPriceData);

    return hasActiveCartRuleSpecialPrice ? cartRuleSpecialPriceData.price : null;
};
