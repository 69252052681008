import React, { PureComponent } from 'react';
import LivePromoComponent from './LivePromo.component';
import PromotionsQuery from './../../query/Promotions.query';
import { fetchQuery } from 'Util/Request';
import {isRoStore} from "Util/Store";

class LivePromoContainer extends PureComponent {
    state = {
        data: null,
        currencySymbol: 'lei' // Default currency symbol
    };

    componentDidMount() {
        this.fetchPromoData();

        this.pollingInterval = setInterval(() => {
            this.fetchPromoData();
        }, 30 * 60 * 1000);

        // Get the store ID and set the currency symbol based on it
        const storeId = isRoStore();
        const currencySymbol = storeId === true ? 'lei' : '€';
        this.setState({ currencySymbol });
    }

    componentWillUnmount() {
        clearInterval(this.pollingInterval);
    }

    fetchPromoData = async () => {
        const query = PromotionsQuery.getPromoItemsForPopupQuery();

        try {
            const response = await fetchQuery(query);

            if (response && response.promoItemsForPopup) {
                this.setState({ data: response.promoItemsForPopup });
            }
        } catch (error) {
            console.error('Error fetching live promo data:', error);
        }
    };

    render() {
        const { data, currencySymbol } = this.state;

        if (!data || !data.enable) {
            return null;
        }

        return (
            <LivePromoComponent
                data={data}
                currencySymbol={currencySymbol} // Pass currency symbol as a prop
            />
        );
    }
}

export default LivePromoContainer;
