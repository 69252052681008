import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_IS_LEGAL_PERSON
} from './Checkout.action';

/** @namespace Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    isLegalPerson: false,
    shippingFields: {},
    email: '',
    isEmailAvailable: true
});

/** @namespace Store/Checkout/Reducer/checkoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_SHIPPING_FIELDS:
            const {shippingFields} = action;

            return {
                ...state,
                shippingFields
            };

        case UPDATE_EMAIL:
            const {email} = action;
            return {
                ...state,
                email
            };

        case UPDATE_EMAIL_AVAILABLE:
            const {isEmailAvailable} = action;
            return {
                ...state,
                isEmailAvailable
            };

        case UPDATE_IS_LEGAL_PERSON:
            const {isLegalPerson} = action;
            return {
                ...state,
                isLegalPerson
            };

        default:
            return state;
    }
};

export default CheckoutReducer;
