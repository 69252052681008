import { connect } from 'react-redux';
import {
    NavigationTabsContainer as SourceNavigationTabsContainer
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { history as browserHistory } from 'Route';

import { SEARCH, CART, MENU, MY_ACCOUNT } from 'Component/Header/Header.config';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { hideActiveOverlay, toggleOverlayByKey, hideActiveOverlayExcept } from 'Store/Overlay/Overlay.action';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { appendWithStoreCode } from 'Util/Url';
import { closeMyAccountPopover }  from 'Store/MyAccount/MyAccount.action';
import NavigationTabs from './NavigationTabs.component';
import {
    ACCOUNT_TAB,
    CART_TAB,
    CHECKOUT_TAB,
    HOME_TAB,
    MENU_TAB
} from 'SourceComponent/NavigationTabs/NavigationTabs.config';
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[BOTTOM_NAVIGATION_TYPE].navigationState,
    headerState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    wishlistCount: Object.keys(state.WishlistReducer.productsInWishlist).length,
    cartTotals: state.CartReducer.cartTotals,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    freeShipping: state.ConfigReducer.freeShipping,
    code: state.ConfigReducer.code,
    popoverClosedByUser: state.MyAccountReducer.popoverClosedByUser
});

export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    hideActiveOverlayExcept: (overlayKey) => dispatch(hideActiveOverlayExcept(overlayKey)),
    setNavigationState: (stateName) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, stateName)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(BOTTOM_NAVIGATION_TYPE)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    closePopover: () => dispatch(closeMyAccountPopover())
});

class NavigationTabsContainer extends SourceNavigationTabsContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
        onSearchButtonClick: this.onSearchButtonClick.bind(this),
        onPopoverClosedByUser: this.onPopoverClosedByUser.bind(this),
        onPromoButtonClick: this.onPromoButtonClick.bind(this)
    };

    routeMap = {
        '/my-account': { name: ACCOUNT_TAB },
        '/checkout': { name: CHECKOUT_TAB},
        '/cart': { name: CART_TAB },
        '/': { name: HOME_TAB }
    };

    containerProps = () => {
        const {
            popoverClosedByUser
        } = this.props;

        return { popoverClosedByUser };
    };

    handleScroll = () => {
        // const { navigationState: { isVisibleOnScroll } } = this.props;
        // if (!isVisibleOnScroll) {
        //     return;
        // }

        const { headerState: { name } } = this.props;

        if (
            name === 'default'
        ) {
            return;
        }

        const windowY = window.scrollY;
        // this.handleNavVisibilityOnScroll(windowY);
        this.scrollPosition = windowY;
    };

    handleVisibleOnScrollChange(prevProps) {
        return;
    }

    onMenuButtonClick() {
        const {
            headerState: { name },
            setHeaderState,
            goToPreviousHeaderState
        } = this.props;

        if (name === MENU) {
            browserHistory.goBack()

            goToPreviousHeaderState();
        } else {

            if (browserHistory.location.pathname !== '/menu') {
                browserHistory.push(appendWithStoreCode('/menu'));
            }

            setHeaderState({ name: MENU });
        }
    }

    onWishlistButtonClick() {
        const { isSignedIn, showNotification } = this.props;
        if (isSignedIn) {
            browserHistory.push({ pathname: appendWithStoreCode('/my-account/my-wishlist') });
        } else {
            showNotification('info', __('Go to your account in order to add Favorite products'));
        }
    }

    onPromoButtonClick() {
        browserHistory.push({ pathname: appendWithStoreCode('/promo') });
    }

    onSearchButtonClick() {
        const {
            headerState: { name },
            showOverlay,
            hideActiveOverlay,
            goToPreviousHeaderState,
            setHeaderState
        } = this.props;

        if (name === SEARCH) {
            hideActiveOverlay()
            goToPreviousHeaderState()
        } else {
            showOverlay(SEARCH);

            setHeaderState({
                name: SEARCH
            });
        }
    }

    onHomeButtonClick() {
        const {
            hideActiveOverlay,
            setHeaderState
        } = this.props;

        const { pathname } = location;

        browserHistory.push(appendWithStoreCode('/'));
        hideActiveOverlay();

        if (
            pathname === appendWithStoreCode('/')
            || pathname === '/'
        ) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        setHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: true
        });
    }

    onMyAccountButtonClick() {
        const { isSignedIn, setHeaderState } = this.props;

        browserHistory.push(isSignedIn ? appendWithStoreCode(`/${ MY_ACCOUNT }`) : appendWithStoreCode(`/account/login`));

        setHeaderState({
            name: DEFAULT_STATE_NAME
        });
    }

    onPopoverClosedByUser() {
        const { closePopover } = this.props;
        closePopover();
    }

    render() {
        return (
            <NavigationTabs
                { ...this.containerProps() }
                { ...this.props }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
