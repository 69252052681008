export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';
export const PAYU_STEP = 'PAYU_STEP';

export const CHECKOUT_URL = '/checkout';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';
export const STRIPE_AUTH_REQUIRED = 'Authentication Required: ';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms
