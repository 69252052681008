import { getIndexedProducts } from 'Util/Product';
import {
    CLEAR_SEARCH_RESULTS,
    UPDATE_SEARCH_BAR,
    UPDATE_SEARCH_LOAD_STATUS
} from './SearchBar.action';

export const initialState = {
    productsInSearch: [],
    categoriesInSearch: {},
    pagesInSearch: {},
    isLoading: true
};

const SearchBarReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        const {
            result: {
                search: {
                    products: {
                        items: productsItems
                    },
                    categories,
                    pages
                }
            }
        } = action;

        return {
            ...state,
            productsInSearch: getIndexedProducts(productsItems),
            categoriesInSearch: categories,
            pagesInSearch: pages
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: initialState.productsInSearch,
            categoriesInSearch: initialState.categoriesInSearch,
            pagesInSearch: initialState.categoriesInSearch
        };

    default:
        return state;
    }
};

export default SearchBarReducer;
