import './InstaFeed.style';

import { PureComponent } from 'react';

class InstaFeed extends PureComponent {
    render() {
        const { instagram, isHomepage, code } = this.props;

        if (code !== 'default') {
            return null;
        }

        if (!isHomepage) {
            return null;
        }

        return (
            <div className="Section Section_Instagram">
                <div className="Section-Title"><span>Instafeed</span></div>
                <div className="Section-Content">
                    <div className="InstaFeed-Scroll">
                        <div className="InstaFeed">
                            { instagram.map((insta, index) => (
                                <a href="https://www.instagram.com/gerovital.romania/" target="_blank" key={ index }><img src={ insta.src } alt={ insta.name } /></a>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstaFeed;
