import DataContainer from 'Util/Request/DataContainer';
import { withRouter } from 'react-router';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { history } from 'Route';
import MenuQuery from 'Query/Menu.query';
import MenuHelper from 'Util/Menu';
import Menu from './Menu.component';
import {connect} from "react-redux";

export const mapStateToProps = state => ({
    isSignedIn: state.MyAccountReducer.isSignedIn
});

export class MenuContainer extends DataContainer {
    state = {
        menu: {},
        activeMenuItemsStack: []
    };

    containerFunctions = {
        closeMenu: this.closeMenu.bind(this),
        onFirstLevelHover: this.onFirstLevelHover.bind(this)
    };

    componentDidMount() {
        this._getMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSignedIn !== prevProps.isSignedIn) {
            this._getMenu();
        }
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery({
                identifier: 'new-main-menu'
            })],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            })
        );
    }

    containerProps = () => {
        const {
            location: {
                pathname
            }
        } = history;
        const isCheckout = pathname.includes(CHECKOUT_URL);

        const { menu, activeMenuItemsStack } = this.state;

        return {
            menu,
            isCheckout,
            activeMenuItemsStack
        };
    };

    onFirstLevelHover(item_id) {
        const { activeMenuItemsStack } = this.state;

        if (!activeMenuItemsStack.includes(item_id)) {
            this.setState({ activeMenuItemsStack: [item_id] });
        }
    }

    closeMenu() {
        this.setState({ activeMenuItemsStack: [] });
    }

    render() {
        return (
            <Menu
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps)(MenuContainer));
