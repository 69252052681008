import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showPopup } from 'Store/Popup/Popup.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { isSignedIn } from 'Util/Auth';
import NewsletterPopup from './NewsletterPopup.component';

import {
    NEWSLETTER_POPUP,
    NEWSLETTER_DELAY,
    NEWSLETTER_SOFT_CLOSE,
    NEWSLETTER_HARD_CLOSE
} from './NewsletterPopup.config';

export const mapStateToProps = state => ({
    customer: state.MyAccountReducer.customer,
    code: state.ConfigReducer.code,
    newsletterConfig: state.ConfigReducer.newsletter,
    promotionConfig: state.ConfigReducer.getPopupInformation
});

export const mapDispatchToProps = dispatch => ({
    showPopup: payload => dispatch(showPopup(NEWSLETTER_POPUP, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

export class NewsletterPopupContainer extends PureComponent {
    containerFunctions = ({
        handleClosePopup: this.handleClosePopup.bind(this),
        handleAlreadySubscribed: this.handleAlreadySubscribed.bind(this),
        handleNewsletterSuccess: this.handleNewsletterSuccess.bind(this)
    });

    componentDidMount() {
        this.openPopup();
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;
        const { location: { pathname: prevPathname } } = prevProps;

        if (pathname !== prevPathname) {
            this.openPopup();
        }
    }

    containerProps = () => {
        const {code, newsletterConfig} = this.props;

        return {
            code,
            newsletterConfig
        };
    };

    openPopup() {
        const {showPopup, customer, promotionConfig, newsletterConfig} = this.props;

        const customerIsSubscribed = isSignedIn() ? customer?.is_subscribed : false;

        if (promotionConfig?.enabled || !newsletterConfig?.enabled) {
            return;
        }


        if (!BrowserDatabase.getItem(NEWSLETTER_POPUP) && !customerIsSubscribed) {
            setTimeout(() => {
                const {location: {pathname}} = this.props;
                const pageWhereNotShowing = [
                    '/cart',
                    '/checkout/shipping',
                    '/checkout/billing',
                    '/checkout/success',
                    '/miercuri',
                    '/newsletter',
                    '/account/login',
                    '/account/register',
                    '/account/forgotPassword'
                ];

                if (!BrowserDatabase.getItem(NEWSLETTER_POPUP) && !pageWhereNotShowing.includes(pathname)) {
                    showPopup({
                        action: NEWSLETTER_POPUP,
                        title: __('Newsletter')
                    });
                }
            }, NEWSLETTER_DELAY);
        }
    }

    handleClosePopup(expirationTime = NEWSLETTER_SOFT_CLOSE) {
        const { hideActiveOverlay, goToPreviousHeaderState } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();

        BrowserDatabase.setItem(true, NEWSLETTER_POPUP, expirationTime);
    }

    handleAlreadySubscribed() {
        this.handleClosePopup(NEWSLETTER_HARD_CLOSE);
    }

    handleNewsletterSuccess() {
        this.handleClosePopup(NEWSLETTER_HARD_CLOSE);
    }

    render() {
        const {promotionConfig, newsletterConfig} = this.props;

        if (promotionConfig?.enabled || !newsletterConfig?.enabled) {
            return null;
        }

        return (
            <NewsletterPopup
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsletterPopupContainer));
