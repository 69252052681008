import {
    UPDATE_BANNERS
} from './Banners.action';

export const initialState = {
    banners: {
        isLoading: true,
        items: []
    }
};

const BannersReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_BANNERS:
        const { banners } = action;
        return {
            ...state,
            banners: {
                isLoading: false,
                items: banners
            }
        };
    default:
        return state;
    }
};

export default BannersReducer;
