import SourceForm from 'SourceComponent/Form/Form.component';

export default class Form extends SourceForm {
    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields
        } = Form.cloneAndValidateChildren(propsChildren, refMap);

        this.setState({ children, fieldsAreValid });

        const inputValues = Object.values(refMap).reduce((inputValues, input) => {
            const { current } = input;
            if (current && current.id && current.value) {
                const { name, value, checked } = current;

                if (current.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (current.type === 'checkbox') {
                    const boolValue = checked;
                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        if (invalidFields.length) {
            const { current } = refMap[invalidFields[0]];

            current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }

        if (inputValues.dob) {
            const dob = inputValues.dob.split('/').reverse().join('-');
            inputValues.dob = dob;
        }

        return {
            inputValues,
            invalidFields
        };
    };
}
