import {
    CmsPageContainer as SourceCmsPageContainer
} from 'SourceRoute/CmsPage/CmsPage.container';
import { connect } from 'react-redux';
import { debounce } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';
import { isSignedIn } from 'Util/Auth';
import { history } from 'Route';
import { updateMeta } from 'Store/Meta/Meta.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import BrowserDatabase from 'Util/BrowserDatabase';

import { CMS_PAGE } from 'Component/Header/Header.config';
import { LOADING_TIME } from 'Route/CmsPage/CmsPage.config';
import {LOGGED_IN_ONLY_PAGES} from "Route/UrlRewrites/LoggedInOnly.config";

const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    loggedInOnlyPages: state.ConfigReducer.cms_LIO
});


export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithCmsPage(breadcrumbs, dispatch)
    ),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => {
        // If og_image_url is available, include it in the meta object
        if (meta.og_image_url) {
            return dispatch(
                updateMeta({
                    title: meta.title,
                    description: meta.description,
                    canonical_url: meta.canonical_url,
                    og_image: meta.og_image_url, // Set the og_image meta tag with og_image_url
                })
            );
        }

        // If og_image_url is not available, just update the other meta fields
        return dispatch(
            updateMeta({
                title: meta.title,
                description: meta.description,
                canonical_url: meta.canonical_url,
            })
        );
    },
    toggleBreadcrumbs: state => dispatch(toggleBreadcrumbs(state))
});

export class CmsPageContainer extends SourceCmsPageContainer {

    componentDidUpdate(prevProps) {
        const {
            location,
            pageIdentifiers,
            pageIds
        } = this.props;

        const {
            location: prevLocation,
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;

        if (
            location?.pathname !== prevLocation?.pathname
            || pageIds !== prevPageIds
            || pageIdentifiers !== prevPageIdentifiers
        ) {
            this.requestPage();
        }
    }

    onPageLoad = ({ cmsPage: page }) => { // remove breadcrumbs from full width page
        const {
            location,
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
            toggleBreadcrumbs,
            loggedInOnlyPages
        } = this.props;

        const {
            content_heading,
            meta_title,
            meta_description,
            title,
            page_width,
            blackfriday,
            og_image_url
        } = page;

        if (loggedInOnlyPages?.includes(location?.pathname) && !isSignedIn()) {
             history.replace(`/my-account/login?redirectTo=${location?.pathname}`);
        }

        if (blackfriday && !BrowserDatabase.getItem('bf-redirect-2022')) {
            BrowserDatabase.setItem(true, 'bf-redirect-2022', 120);
            history.push(`/${blackfriday}`);
        }

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        if (page_width === 'full') {
            toggleBreadcrumbs(false);
        } else {
            updateBreadcrumbs(page);
        }

        updateMeta({
            title: meta_title || title,
            description: meta_description,
            canonical_url: window.location.href,
            og_image_url
        });

        if (
            location?.pathname !== appendWithStoreCode('/')
            && location?.pathname !== '/'
        ) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        this.setState({ page, isLoading: false });

        if (history.location?.hash) {

            let offset = 0;
            if (window.innerWidth >= 1366) {
                offset = 100;
            } else if (window.innerWidth <= 767) {
                offset = 65;
            }

            setTimeout(function() {
                const elem = document.querySelector(history.location?.hash);

                window.scrollTo({
                    top: elem.getBoundingClientRect().top + window.scrollY - offset,
                    behavior: 'smooth'
                });
            }, 500)

        }
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
