export const STATE_SIGN_IN = 'signIn';
export const STATE_FORGOT_PASSWORD = 'forgotPassword';
export const STATE_FORGOT_PASSWORD_SUCCESS = 'forgotPasswordSuccess';
export const STATE_CREATE_ACCOUNT = 'createAccount';
export const STATE_LOGGED_IN = 'loggedIn';
export const STATE_CONFIRM_EMAIL = 'confirmEmail';

export const CUSTOMER_ACCOUNT_OVERLAY_KEY = 'customer_account';

export const SOCIAL_LOGIN_PROVIDERS = {
    facebook: '<svg viewBox="0 0 24 24" height="16" fill="#FFF" xmlns="http://www.w3.org/2000/svg"><path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"/></svg>',
    google: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><defs><style>.a{fill:#fff}</style></defs><path class="a" d="M3.546 9.669l-.557 2.084-2.036.043a8.014 8.014 0 01-.059-7.47l1.812.332.794 1.8a4.774 4.774 0 00.045 3.214zM15.86 6.505a8 8 0 01-2.852 7.733l-2.283-.116-.323-2.017a4.768 4.768 0 002.051-2.435H8.175V6.505h7.684z"/><path class="a" d="M13.007 14.238A8 8 0 01.952 11.791l2.593-2.122a4.758 4.758 0 006.856 2.436zM13.106 1.842l-2.592 2.122A4.757 4.757 0 003.5 6.455L.894 4.321a8 8 0 0112.212-2.479z"/></svg>'
};
