/* eslint-disable react/require-render-return */

import {
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

import { DEFAULT_STATE_NAME } from 'SourceComponent/NavigationAbstract/NavigationAbstract.config';

export const DEFAULT_STATE = { name: DEFAULT_STATE_NAME };

export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    handleDesktopRouteChange() {
        const {
            hideActiveOverlayExcept,
            setNavigationState
        } = this.props;

        setNavigationState(this.routeMap['/']);

        // Removed this for now, it's also removed on mobile route changed in the base class
        // so the deepAR popover remains opened after a variant is changed from it's swatch list
        // UPDATE: due to a bug on desktop where the cart popup doesn't close, changed to a hacky workaround, to close
        // all popups as before, except the DeepAr overlay
        // TODO - should probably move this from NavigationAbstract as this component should not be tightly coupled to any specific component
        hideActiveOverlayExcept('DeepAr_Overlay_id');

        return {};
    }
}

export default NavigationAbstractContainer;
