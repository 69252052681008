import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CmsBlock from 'Component/CmsBlock';
import isMobile from 'Util/Mobile';
import './Footer.style';

import {
    ACCOUNT_TAB, CART_TAB, MENU_TAB
} from 'Component/NavigationTabs/NavigationTabs.config';


/**
 * Page footer
 * @class Footer
 */

export default class Footer extends PureComponent {
    static propTypes = {
        bottomTabName: PropTypes.string,
        isCheckout: PropTypes.bool.isRequired
    };

    static defaultProps = {
        bottomTabName: ''
    };

    render() {
        const { bottomTabName, isCheckout } = this.props;

        if ([
            ...(isMobile.any() ? [MENU_TAB] : []),
            ACCOUNT_TAB,
            ...(isMobile.any() ? [CART_TAB] : [])
        ].includes(bottomTabName)) {
            return null;
        }

        if (isCheckout) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">
                <div block="Footer" elem="Top">
                    <div block="Footer" elem="Container">
                        <div block="Footer" elem="Links">
                            <CmsBlock identifier="footer-links-1" collapsible mix="Footer-Group" />
                            <CmsBlock identifier="footer-links-2" collapsible mix="Footer-Group" />
                            <CmsBlock identifier="footer-links-3" collapsible mix="Footer-Group" />
                            <CmsBlock identifier="footer-links-4" collapsible mix="Footer-Group" />
                            <CmsBlock identifier="footer-links-5" collapsible mix="Footer-Group" />
                        </div>
                    </div>
                </div>
                <div block="Footer" elem="Bottom">
                    <div block="Footer" elem="Social">
                        <CmsBlock identifier="footer-social" />
                    </div>
                    <div block="Footer" elem="Netlogiq">
                        © {new Date().getFullYear()} { __('Online store built by') }
                    </div>
                    <div block="Footer" elem="Payment">
                        <CmsBlock identifier="footer-payment" />
                    </div>
                </div>
            </footer>
        );
    }
}
