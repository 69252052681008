export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
export const CLEAR_PRODUCT_DETAILS = 'CLEAR_PRODUCT_DETAILS';

export const updateProductDetails = (product) => ({
    type: UPDATE_PRODUCT_DETAILS,
    product
});

export const clearProductDetails = () => ({
    type: CLEAR_PRODUCT_DETAILS
});
