import SourceNavigationTabs from 'SourceComponent/NavigationTabs/NavigationTabs.component';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import Html from 'Component/Html';

import { MENU, POPOVER_CLOSED_BY_USER } from 'Component/Header/Header.config';

const accountSvg = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27"><path d="M24.859 17.14a12.647 12.647 0 00-3.226-2.019 1.055 1.055 0 10-.857 1.928 10.548 10.548 0 012.69 1.676 4.206 4.206 0 011.425 3.161v1.951a1.056 1.056 0 01-1.055 1.055H3.164a1.056 1.056 0 01-1.054-1.056v-1.951a4.206 4.206 0 011.425-3.161C4.6 17.788 7.7 15.61 13.5 15.61a7.814 7.814 0 10-4.754-1.62 14.663 14.663 0 00-6.6 3.149A6.315 6.315 0 000 21.885v1.951A3.168 3.168 0 003.164 27h20.672A3.168 3.168 0 0027 23.836v-1.951a6.315 6.315 0 00-2.142-4.745zM7.8 7.805a5.7 5.7 0 115.7 5.7 5.7 5.7 0 01-5.7-5.7zm0 0"/></svg>;

class NavigationTabs extends SourceNavigationTabs {

    renderMap = {
        ...this.renderMap,
        search: this.renderSearchButton.bind(this),
        //wishlist: this.renderWishlistButton.bind(this)
        promo: this.renderPromoButton.bind(this)
    };

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick, code } = this.props;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                mods={ { home: true } }
                aria-label={ __('Home') }
                onClick={ onHomeButtonClick }
            >
                { code !== 'default'
                    ? <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAjCAYAAAD17ghaAAAABmJLR0QA/wD/AP+gvaeTAAADC0lEQVRYw7WYPXIbRxCFvzeEYGZG6Ey8gTeHVIJPoFXmcH0CwVVa1HITIiJBwFWCI4cmT8DVCQyWSZeUgScQfQIDmQBVbTvAj/iDHfwQ6AzYnn6v3870dK9Y0+I4DSRem6wGgCmQqACY0Uc2mHgqI+ey0znu++JpFdB6vVkpl0dHiBB0sA5hMwaCrnP5eavVul2LwAR4/NagPsvyKWZw9nVU/rXbbQ6WEojjNJCzi3UzXkURc/mb31qtHsDeIqdG4zBEupD0wzbBAST2haJq9cXw+vrqY6kQfNvI93UYmqkH4O7+HcdpYOjPnWIDGNHsdMwVqNebFbnRBaiydL3ZJU5nX7+Us7sbCuBdktRkLsQIJZ4/Wit+6ZyeZLPfc6UbjbSJOFomHUbUbn8L4LM4SSOZdUHfT5mft9sn0V0fzbJ/Vh5/9h81u3GycNFZ9tkk9ihDGnROj8OHz0sA5fKoi3zS23ATcIDpK6rV682F8QUQN9L/vNmbvVlV9nWtNK3theBmdtnZETiAwxH5HATdXYFPCJgFPofx+LveLgmUfA/N7PLhOZ/Zasd2FQXQj5sttf42FHCbXrPObYkA2HCTha1W69bgw5MJmOHJZFpCC2xPeX3TBO4o4IEXQVEFm6uQqwZ2symBElIfeFXk8Gx/HAJnRc+n12oQJ2mk3GoGB1P2Axl9sAHS+0ICpjyTubeFKpgd+QjMibSOzxb5vUuSmrPi9sZNejPfe9RBnKTRphIvs8keMDKvV877OE6DnRFwzpo+J4kK4q9dkNgDuLq6GlSrL4WoeUjsAz9XX7z88s/1359WCd5oHIZCf/javPnumHZF/UV93GOzW4zMTOcPR684PnyFUyCsvspMofuL00DOessK0DbtXiHqdI77mL8/2K7ZzaNK2G6fZLnyn55aYpdCw+/t05OgsEIkSXKQm7LNr+sCYONfc3k0mw2XTmBxkkbkNFfbnH5gHM1pxZzbyiNgnKQRRih4vQbscFrksqKueqMZtNE4DEEBgIkAs8q3gOqBDczUW/Z1BOB/6OJB0mF+yGoAAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=" />
                    : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.01 96.01" height="43">
                            <path fill="#865aa4" d="M48.1 96c-13.24 0-25.27-5.37-34-14.07C5.4 73.23 0 61.23 0 48c0-13.2 5.4-25.2 14.1-33.9C22.84 5.4 34.87 0 48.1 0c13.18 0 25.17 5.4 33.86 14.1 8.66 8.7 14.06 20.7 14.06 33.9 0 .7 0 1.37-1.37 1.37-1.35 0-1.35-.68-1.35-1.36 0-12.48-5.1-23.8-13.26-31.98-8.2-8.2-19.5-13.3-31.95-13.3-12.5 0-23.86 5.1-32.06 13.3-8.23 8.2-13.32 19.5-13.32 32 0 12.47 5.1 23.78 13.32 31.98 8.2 8.2 19.55 13.3 32.05 13.3.68 0 1.35 0 1.35 1.35 0 1.36-.67 1.36-1.36 1.36" />
                            <path fill="#865aa4" d="M43.75 7.84c-.07.26-.42.45-.7.52-3.43 1.5-6.6 4.25-6.6 8.5 0 5.23 4.23 9.46 9.45 9.46 5.13 0 9.38-4.23 9.38-9.45 0-1.77-.2-3.9-1.06-5.32 0 0-2.74-4.5-6.1-6.28-2.58-1.32-5.86-2.55-9.74-1.68-1.77.35-4.33.97-5.84 2.46-4.87 4.95-5.48 8.85-6.27 16.46-.72 7.6-4.06 7.7-9.58 9.04-1.6.35-3.97.53-5.83.53-4.7.17-9.02 0-7.52.42 2.2.63 9.38 1.6 15.73 3.27 6.37 1.68 7.8 4.87 9.48 10.44 1.78 5.48 3.27 16.9 3.98 25.32.8 8.3 2.2 20.85 2.2 20.78 0-.08 1.6-22.65 2.57-32.3.9-9.55-.7-18.38 7.18-22.56 7.96-4.17 46.64-4.68 46.9-5.04.25-.35-26.65-.44-37.25-1.5-10.63-1.15-16.46-1.25-19.93-7.52-3.34-6.22-2.54-13 3.64-16.55 5.15-3 6.18.52 5.9.97" />
                        </svg>
                    )
                }
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const {
            headerState: { name },
            onMenuButtonClick
        } = this.props;

        return (
            <button
              key="menu"
              block="NavigationTabs"
              elem="Button"
              mods={ { menu: true } }
              aria-label={ __('Go to menu and search') }
              onClick={ onMenuButtonClick }
            >
                { name === MENU
                    ? <svg height="25" viewBox="0 0 311 311.07733" width="25" xmlns="http://www.w3.org/2000/svg"><path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"/><path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"/></svg>
                    : <svg height="25" viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg"><path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" /></svg>
                }
                <span>{ __('menu') }</span>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        const indicatorStyle = isSignedIn() ? 'IndicatorLoggedIn' : 'IndicatorLoggedOut';

        return (
            <div
                key="account"
                block="NavigationTabs"
                elem="ButtonWrapper"
                mods={ { account: true } }
            >
                <button
                    key="account"
                    block="NavigationTabs"
                    elem="Button"
                    mods={ { isActive, account: true } }
                    onClick={ onMyAccountButtonClick }
                    aria-label={ __('Open my account') }
                >
                    <div className="AccountIcon">
                        <span className={ indicatorStyle }/>
                        { accountSvg }
                    </div>

                    <span>{ __('account') }</span>
                </button>
                { this.renderAccountButtonPopover() }
            </div>
        );
    }

    renderAccountButtonPopover() {
        const { popoverClosedByUser } = this.props;
        const isLoggedIn = isSignedIn();
        if (popoverClosedByUser || BrowserDatabase.getItem(POPOVER_CLOSED_BY_USER)) {
            return null;
        }

        const { freeShipping: { guest_message, logged_in_user_message }, onPopoverClosedByUser } = this.props;
        const message = isLoggedIn ? logged_in_user_message : guest_message;
        if (!message) {
            return null;
        }

        return (
            <div block="NavigationTabs" elem="Popover">
                <Html content={ message } />
                <svg onClick={ () => { onPopoverClosedByUser() ; BrowserDatabase.setItem(true, POPOVER_CLOSED_BY_USER, 86400); } } xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
        )
    }

    renderMinicartButton(isActive = false) {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
              key="mincart"
              block="NavigationTabs"
              elem="Button"
              mods={ { minicart: true } }
              onClick={ onMinicartButtonClick }
              aria-label={ __('Minicart') }
            >
                { this.renderMinicartItemsQty() }
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 0 19.333 27.067"
                >
                    <path
                      d="M18.367 5.8h-2.9v-.967A4.839 4.839 0 0010.633 0H8.7a4.839 4.839 0 00-4.833 4.833V5.8h-2.9A.967.967 0 000 6.767v17.4a2.9 2.9 0 002.9 2.9h13.533a2.9 2.9 0 002.9-2.9v-17.4a.967.967 0 00-.966-.967zM17.4 24.167a.967.967 0 01-.967.967H2.9a.967.967 0 01-.967-.967V7.733h1.934v2.9a.967.967 0 101.933 0v-2.9h4.833a.967.967 0 000-1.933H5.8v-.967a2.9 2.9 0 012.9-2.9h1.933a2.9 2.9 0 012.9 2.9v5.8a.967.967 0 001.933 0v-2.9H17.4z"
                    />
                </svg>
                <span>{ __('cart') }</span>
            </button>
        );
    }

    renderSearchButton() {
        const { onSearchButtonClick } = this.props;

        return (
            <button
              key="call"
              block="NavigationTabs"
              elem="Button"
              mods={ { call: true } }
              aria-label={ __('Search') }
              onClick={ onSearchButtonClick }
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 26 26">
                    <path d="M10.664 0a10.659 10.659 0 018.2 17.474l6.836 6.79a1.016 1.016 0 11-1.432 1.436l-6.845-6.8a10.658 10.658 0 01-11.98 1.058 1.016 1.016 0 011-1.77 8.628 8.628 0 10-3.28-3.26 1.016 1.016 0 01-1.764 1.006A10.667 10.667 0 0110.664 0z" fill="#747081" />
                </svg>
                <span>{ __('search') }</span>
            </button>
        );
    }

    renderWishlistItemsQty() {
        const { wishlistCount } = this.props;

        if (wishlistCount === 0) {
            return null;
        }

        return <span block="Header" elem="MinicartItemCount">{ wishlistCount }</span>;
    }

    renderWishlistButton() {
        const { onWishlistButtonClick } = this.props;

        return (
            <button
              key="wishlist"
              block="NavigationTabs"
              elem="Button"
              mods={ { wishlist: true } }
              onClick={ onWishlistButtonClick }
            >
                { this.renderWishlistItemsQty() }
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 0 32.161 27"
                >
                    <path
                      d="M16.081 27a2.942 2.942 0 01-1.463-.388 42.358 42.358 0 01-8.427-6.331C2.083 16.219 0 12.03 0 7.83a8.177 8.177 0 011.877-5.742A7.2 7.2 0 017.412 0c3.95 0 7.086 2.857 8.641 4.614C17.556 2.843 20.612 0 24.749 0a7.154 7.154 0 015.541 2.141 8.162 8.162 0 011.871 5.689c0 3.472-1.439 6.962-4.277 10.373a1.256 1.256 0 11-1.931-1.603c2.452-2.948 3.7-5.9 3.7-8.766 0-3.578-1.6-5.318-4.9-5.318-4.484 0-7.577 4.771-7.608 4.819a1.259 1.259 0 01-1.04.582h-.02a1.256 1.256 0 01-1.038-.547c-.032-.047-3.384-4.854-7.631-4.854-3.343 0-4.9 1.69-4.9 5.318 0 3.5 1.832 7.092 5.445 10.664a40.488 40.488 0 007.91 5.938.432.432 0 00.427 0 42.084 42.084 0 005.646-3.9 1.257 1.257 0 111.6 1.939 44.688 44.688 0 01-5.994 4.142 2.943 2.943 0 01-1.469.383zm0 0"
                    />
                </svg>
                <span>{ __('wishlist') }</span>
            </button>
        );
    }

    renderPromoButton() {
        const { onPromoButtonClick } = this.props;

        return (
            <button
                key="promo"
                block="NavigationTabs"
                elem="Button"
                mods={ { promo: true } }
                onClick={ onPromoButtonClick }
                aria-label={__('promotions')}
                className="NavigationTabs-Button_wishlist"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="25"
                    viewBox="3 1 20 20"
                >
                    <g transform="translate(0.000000,26.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path d="M112 216 c-6 -15 -23 -27 -41 -31 -35 -8 -39 -24 -12 -49 11 -10 17 -25 14 -36 -3 -11 -3 -27 1 -35 5 -14 9 -14 34 -1 23 12 31 12 52 1 32 -17 40 -11 40 29 0 19 7 39 15 46 24 20 17 40 -16 47 -19 3 -35 15 -41 29 -14 31 -32 30 -46 0z m48 -27 c0 -3 14 -9 32 -13 31 -6 31 -6 12 -27 -12 -14 -17 -32 -15 -55 l3 -35 -29 15 c-19 9 -34 11 -44 5 -28 -17 -42 -11 -36 18 4 20 -1 35 -15 50 l-21 23 30 6 c20 4 35 15 43 32 11 26 12 26 26 7 8 -10 14 -22 14 -26z" />
                    </g>
                </svg>
                <span>{__('promotions')}</span>
            </button>
        );
    }

}

export default NavigationTabs;
