import { getIndexedProduct } from 'Util/Product';
import { UPDATE_PRODUCT_DETAILS, CLEAR_PRODUCT_DETAILS } from './Product.action';
import { GET_REVIEWS } from '../Reviews/Reviews.action';

export const initialState = {
    product: {},
    formattedConfigurableOptions: {}
};

export const formatConfigurableOptions = (configurable_options) => configurable_options
    .reduce((prev, option) => {
        const {
            attribute_id,
            label,
            attribute_code
        } = option;

        return {
            ...prev,
            [attribute_code]: {
                attribute_id,
                label
            }
        };
    }, {});

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_REVIEWS:
        return {
            ...state,
            product: {
                ...state.product,
                reviews: action.reviewsList.reviewsList.reviews,
                reviewsTotalPages: parseInt(action.reviewsList.reviewsList.total_pages, 10)
            }
        };
    case UPDATE_PRODUCT_DETAILS:
        const { product } = action;
        product.reviews = product.reviewsList.reviews;
        product.reviewsTotalPages = parseInt(product.reviewsList.total_pages, 10);
        delete product.reviewsList;

        return {
            ...state,
            product: getIndexedProduct(product)
        };

    case CLEAR_PRODUCT_DETAILS:
        return {
            ...state,
            product: {}
        }

    default:
        return state;
    }
};

export default ProductReducer;
