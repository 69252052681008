import { LinkedProductsDispatcher as SourceLinkedProductsDispatcher } from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';

import ProductListQuery from 'Query/ProductList.query';

export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    prepareRequest(product_links) {
        const relatedSKUs = product_links.sort((a, b) => a.position - b.position).reduce((links, link) => {
            const { linked_product_sku } = link;
            return [...links, `"${ linked_product_sku.replace(/ /g, '%20') }"`];
        }, []);

        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: relatedSKUs
                    }
                },
                notRequireInfo: true
            })
        ];
    }
}

export default new LinkedProductsDispatcher();
