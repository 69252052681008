import SourceAddToCart from 'SourceComponent/AddToCart/AddToCart.component';

export default class AddToCart extends SourceAddToCart {
    isOutOfStock() {
        const {
            configurableVariantIndex,
            product,
        } = this.props;

        const { type_id, stock_status, variants, salable_quantity_oos } = product;

        if (
            type_id === 'configurable' &&
            configurableVariantIndex !== -1 &&
            variants[configurableVariantIndex].salable_quantity_oos)
        {
            return true;
        }

        if (salable_quantity_oos) {
            return true;
        }

        return false;
    }

    render() {
        const {
            mix,
            product: { type_id, attributes },
            isLoading,
            buttonClick,
            text,
            pdp
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const necomandabilValue = attributes?.necomandabil?.attribute_value ?? null;
        const isNecomandabil = necomandabilValue === "1";

        const isDisabled = isLoading || !type_id;
        const isOutOfStock = this.isOutOfStock() || isNecomandabil;



        return (
            <button
              onClick={ (e) => {
                  buttonClick();
                  e.stopPropagation();
                  e.preventDefault();
              } }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading, isOutOfStock } }
              disabled={ isDisabled }
            >
                { isOutOfStock
                    ? <span>{ pdp ? __('Out of stock') : __('Out of stock') }</span>
                    : (
                        <>
                            <span>{ text && text.normal ? text.normal : __('Add to cart') }</span>
                            <span>{ text && text.loading ? text.loading : __('Adding...') }</span>
                        </>
                    )
                }

            </button>
        );
    }
}
