import SourceUrlRewrites from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import { lazy  } from 'react';

import NoMatch from 'Route/NoMatch';

import GoogleTagManagerRouteWrapperComponent from 'Component/GoogleTagManager/GoogleTagManagerRouteWrapper.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT,
    TYPE_SPECIAL_PROMO
} from 'Route/UrlRewrites/UrlRewrites.config';

import {
    PDP,
    PLP
} from 'Component/Header/Header.config';


export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'));
export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const BlackFridayPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/BlackFridayPage'));

export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return <GoogleTagManagerRouteWrapperComponent route={ PDP }><ProductPage { ...props } /></GoogleTagManagerRouteWrapperComponent>;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_CATEGORY:
            return <GoogleTagManagerRouteWrapperComponent route={ PLP }><CategoryPage { ...props } /></GoogleTagManagerRouteWrapperComponent>;
        case TYPE_SPECIAL_PROMO:
            return <BlackFridayPage { ...props } />
        case TYPE_NOTFOUND:
            return <NoMatch { ...props } />;
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;
